import { AppBar, Toolbar, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function TitleBar({ icon, menu, title, extra }) {
    const { t } = useTranslation()

    return (
        <AppBar position='sticky'>
            <Toolbar display='flex' style={{ width: '100%' }}>
                {icon}
                {menu}
                <Typography variant='h6' style={{ flexGrow: 1 }}>
                    {t(title)}
                </Typography>
                {extra}
            </Toolbar>
        </AppBar>
    )
}
