import LockOutlined from '@mui/icons-material/LockOutlined'
import { Avatar, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import flavorProvider from '../services/FlavorProvider'

const LogoBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: 200,
}))

const LogoAvatar = styled(Avatar)(({ theme }) => ({
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
}))

export default function () {
    const logo = flavorProvider.getLogo()
    return logo ?
        <LogoBox component='img' src={logo} /> :
        <LogoAvatar>
            <LockOutlined />
        </LogoAvatar>
}
