import AuthProvider from "./AuthProvider";

const sessionId = () => AuthProvider.details() && AuthProvider.details().sessionId;

const handleResponse = async (response) => {
    if (response.status >= 400) {
        if (response.status === 401) {
            window.location.href = '#/login'; // eigenlijk via route, hoe?
        }
        let json = {};
        try {
            json = await response.json();
        } catch (e) {
            // If parsing fails, not our result (probably offline)
        }
        return Promise.reject(new Error(json.error || response.statusText));
    }
    if (response.headers.get('Content-Length') === '0' || response.status === 204) {
        return Promise.resolve(null);
    }
    return Promise.resolve(response.json());
}

const req = async (relativeUrl, options) => {
    try {
        const url = process.env.REACT_APP_BASE_REST + relativeUrl;
        const response = await fetch(url, {
            ...options,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'sessionId': sessionId(),
                'olisa-source': "UCONTROL",
            },
        });
        return handleResponse(response);
    } catch (ex) {
        return Promise.reject(new Error("Cancelled"));
    }
}

const handleResponseFile = async (response) => {
    if (response.status >= 400) {
        if (response.status === 401) {
            window.location.href = '#/login'; // eigenlijk via route, hoe?
        }
        let json = {};
        try {
            json = await response.json();
        } catch (e) {
            // If parsing fails, not our result (probably offline)
        }
        return Promise.reject(new Error(json.error || response.statusText));
    }
    if (response.headers.get('Content-Length') === '0' || response.status === 204) {
        return Promise.resolve(null);
    }
    return Promise.resolve(response);
}

const requestFile = async (relativeUrl, options) => {
    try {
        const url = process.env.REACT_APP_BASE_REST + relativeUrl;
        const response = await fetch(url, {
            ...options,
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'sessionId': sessionId(),
                'olisa-source': "UCONTROL",
            },
        });
        return handleResponseFile(response);
    } catch (ex) {
        return Promise.reject(new Error("Cancelled"));
    }
}

const RequestService = {
    post: async (relativeUrl, bodyContents, options) => req(relativeUrl, { ...options, method: 'POST', body: bodyContents ? JSON.stringify(bodyContents) : null }),
    put: async (relativeUrl, bodyContents, options) => req(relativeUrl, { ...options, method: 'PUT', body: bodyContents ? JSON.stringify(bodyContents) : null }),
    delete: async (relativeUrl, options) => req(relativeUrl, { ...options, method: 'DELETE' }),
    get: async (relativeUrl, options) => req(relativeUrl, { ...options, method: 'GET' }),
    getFile: async (relativeUrl, options) => requestFile(relativeUrl, { ...options, method: 'GET' }),
}

export default RequestService