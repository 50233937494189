import { faFlag } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Polyline, Tooltip } from 'react-leaflet'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'
import { usePreferences } from '../hooks/usePreferences'
import { dateToString } from '../services/DateUtils'
import TrackService from '../services/TrackService'
import BottomCollapse from './BottomCollapse'
import { determineBounds } from './Map'
import MapDialog from './MapDialog'
import MapMarker from './MapMarker'
import Track from './Track'

export default function TripMapDialog({ trip, open, toggle }) {
    const controller = new AbortController()
    const preferences = usePreferences()
    const { value: showDetails, setFalse: hideDetails, toggle: toggleDetails } = useBoolean(true)

    const { data: track } = useQuery(['track', trip.id], async () => {
        return await TrackService.get(trip.id, { signal: controller.signal })
    }, {
        enabled: open,
    })

    const { data: positions } = useQuery(['positions', trip.id], async () => {
        return await TrackService.getPositions(trip.id, { signal: controller.signal })
    }, {
        enabled: open,
    })

    const route = useMemo(() => {
        if (!!track && !!positions)
            return [
                [track.startAddress.latitude, track.startAddress.longitude],
                ...positions.map(p => [p.latitude, p.longitude]),
                [track.endAddress.latitude, track.endAddress.longitude]]
    }, [track, positions])

    const bounds = useMemo(() => route?.length ? determineBounds(route) : null, [route])

    const [mapMarkerSize] = preferences.mapMarkerSize
    const green = '#1A6900'
    const red = '#F40B00'

    const startIcon = <FontAwesomeIcon
        icon={faFlag}
        style={{
            fontSize: `${mapMarkerSize}px`,
            position: 'relative',
            left: `${mapMarkerSize / -2}px`,
            top: `${mapMarkerSize / -2}px`,
            color: `${green}`,
        }} />

    const endIcon = <FontAwesomeIcon
        icon={faFlag}
        style={{
            fontSize: `${mapMarkerSize}px`,
            position: 'relative',
            left: `${mapMarkerSize / -2}px`,
            top: `${mapMarkerSize / -2}px`,
            color: `${red}`,
        }} />

    return (
        <MapDialog
            open={open}
            toggle={toggle}
            title='map.trip.title'
            bounds={bounds}
            details={
                <BottomCollapse open={showDetails}>
                    <Track nested track={trip} />
                </BottomCollapse>
            }
            eventHandlers={{
                click: hideDetails,
            }}>
            {route?.length &&
                <Polyline key={trip.id} positions={route}>
                    <MapMarker position={route[0]} icon={startIcon} onClick={toggleDetails}>
                        <Tooltip direction='top' offset={[0, mapMarkerSize / -2]} permanent>
                            {dateToString(trip.start)}
                        </Tooltip>
                    </MapMarker>
                    <MapMarker position={route[route.length - 1]} icon={endIcon} onClick={toggleDetails}>
                        <Tooltip direction='top' offset={[0, mapMarkerSize / -2]} permanent>
                            {dateToString(trip.end)}
                        </Tooltip>
                    </MapMarker>
                </Polyline>
            }
        </MapDialog>
    )
}