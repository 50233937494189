import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function NoResults({ text }) {
    const { t } = useTranslation()
    return (
        <Grid container sx={{ width: 1, height: 1 }} alignContent='center' justifyContent='center'>
            <Typography variant='h6' color='textSecondary' align='center'>{t(text)}</Typography>
        </Grid>
    )
}
