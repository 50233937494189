import React from 'react'
import { Chip, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AttachFile } from '@mui/icons-material';
import { saveAs } from 'file-saver';

import AttachmentService from '../services/AttachmentService';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chip: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

const Attachments = ({ attachmentReferences }) => {
    const classes = useStyles();

    function handleDownload(reference) {
        AttachmentService.getFile(reference.id).then((result) => {
            return result.blob();
        }).then(blob => {
            saveAs(blob, reference.filename);
        });
    }

    return (
        <React.Fragment>
            <Grid className={classes.root}>
                {attachmentReferences.map((reference, index) => (
                    <Chip
                        className={classes.chip}
                        icon={<AttachFile fontSize="small" />}
                        label={reference.filename}
                        onClick={() => handleDownload(reference)}
                        color="primary"
                        key={index} />
                ))}
            </Grid>
        </React.Fragment>
    );
};

export default Attachments;