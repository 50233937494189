import { BehaviorSubject } from 'rxjs';

import req from './RequestService';
import { hashCode } from './StringUtils';

const details = () => {
  let value = localStorage.getItem('session');
  return value && JSON.parse(value);
}

const detailsSubject = new BehaviorSubject(details());

const setDetail = (key, value) => {
  let d = details();
  d[key] = value;
  localStorage.setItem('session', JSON.stringify(d));
  detailsSubject.next(d);
}

const isSignedIn = () => details() !== null;

const AuthProvider = {
  detailsSubject: () => {
    return detailsSubject.asObservable();
  },
  signIn: (organisation, username, password) => {
    return req.post(`sessions/open`, {
      organisation: organisation,
      username: username,
      password: password
    }).then(result => {
      if (!result) {
        throw Error("Failed to log in");
      }
      localStorage.setItem('session', JSON.stringify(result));
      return req.get(`sessions/info`);
    }).then(result => {
      localStorage.setItem('session', JSON.stringify({ ...details(), ...result }));
      detailsSubject.next(details());
      return Promise.resolve(details());
    });
  },
  signOut: async () => {
    if (isSignedIn()) {
      try {
        await req.post(`sessions/close`);
        localStorage.removeItem('session');
        detailsSubject.next(details());
      } catch (e) {
        localStorage.removeItem('session');
        detailsSubject.next(details());
      }
    }
  },
  reset: async (email) => {
    return req.post(`sessions/resetPassword?email=${email}`);
  },
  change: async (currentPassword, newPassword) => {
    await req.post(`sessions/changePassword`, {
      currentPassword: currentPassword,
      newPassword: newPassword,
    }).then(() => {
      setDetail('mustChangePassword', false);
    });
  },
  accept: async () => {
    if (isSignedIn()) {
      try {
        await req.post(`sessions/acceptPrivacyPolicy`).then(() => {
          setDetail('mustAcceptPrivacyPolicy', false);
        });
      } catch {
        localStorage.removeItem('session');
        detailsSubject.next(details());
      }
    }
  },
  hasPrivilege: (name) => {
    let d = details();
    return d && d.privileges && d.privileges.includes(name);
  },
  sessionHash: () => {
    let d = details();
    return d ? hashCode(`${d.firstName}${d.lastName}${d.organisationName}`) : null;
  },
  isSignedIn,
  details,
};

export default AuthProvider;
