import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Grid, IconButton } from '@mui/material'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { DayCalendarSkeleton } from '@mui/x-date-pickers';
import { addDays, getYear, isToday, startOfMonth, startOfToday, subDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { LocalizedAdapter } from '../services/DateUtils'
import { useState, useCallback } from 'react';

export default function CalendarNavigation({ previousDateQuery, summaryQuery, nextDateQuery, selectedDate, setSelectedDate, setSummaryDate, shouldDisableDate, renderDay, renderInput, open, close }) {
    const { t } = useTranslation()

    const [pickerDate, setPickerDate] = useState(new Date(selectedDate))

    const selectDate = useCallback((date) => {
        setSelectedDate(date)
        setPickerDate(date)
    }, [selectedDate])

    const { data: previousDate, isFetching: previousDateIsFetching } = previousDateQuery ?? {
        data: subDays(selectedDate, 1),
        isFetching: false
    }

    const { isFetching: summaryIsFetching } = summaryQuery ?? {
        isFetching: false
    }

    const { data: nextDate, isFetching: nextDateIsFetching } = nextDateQuery ?? {
        data: addDays(selectedDate, 1),
        isFetching: false
    }

    const formats = {
        keyboardDate: 'EEEEEE d MMMM',
    }

    return (
        <Grid container alignItems='center' justifyContent='space-between'>
            <Grid item>
                <IconButton
                    disabled={previousDateIsFetching || !previousDate}
                    onClick={() => {
                        setSummaryDate?.(startOfMonth(previousDate))
                        selectDate(previousDate)
                    }}
                    size='large'>
                    <ChevronLeft />
                </IconButton>
            </Grid>
            <Grid item>
                <LocalizationProvider adapterLocale={LocalizedAdapter.locale} dateAdapter={LocalizedAdapter} dateFormats={formats} localeText={{ okButtonLabel: t('calendar.close'), todayButtonLabel: t('calendar.today') }}>
                    <MobileDatePicker
                        disableFuture
                        disableMaskedInput
                        value={selectedDate}
                        onClose={() => {
                            close()
                        }}
                        onOpen={() => {
                            setPickerDate(selectedDate)
                        }}
                        onAccept={(date) => {
                            selectDate(date)
                            close()
                        }}
                        onChange={(date) => {
                            setPickerDate(date)
                        }}
                        onMonthChange={(date) => {
                            setSummaryDate?.(startOfMonth(date))
                        }}
                        onYearChange={(date) => {
                            setSummaryDate?.(startOfMonth(date))
                        }}
                        open={open}
                        slots={{
                            textField: renderInput,
                            day: renderDay,
                        }}
                        slotProps={{
                            toolbar: {
                                sx: {
                                    bgcolor: 'primary.main',
                                },
                                hidden: false,
                                toolbarTitle: getYear(pickerDate),
                            },
                            day: { selectedDay: selectedDate },
                            actionBar: { actions: ['today', 'accept'] },
                            layout: {
                                sx: {
                                    '& .MuiDayCalendar-monthContainer': {
                                        paddingTop: '4px',
                                    },
                                },
                            },
                        }}
                        shouldDisableDate={shouldDisableDate}
                        loading={summaryIsFetching}
                        renderLoading={() => <DayCalendarSkeleton />}
                        renderDay={renderDay}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item>
                <IconButton
                    disabled={nextDateIsFetching || isToday(selectedDate)}
                    onClick={() => {
                        setSummaryDate?.(startOfMonth(nextDate ?? startOfToday()))
                        selectDate(nextDate ?? startOfToday())
                    }}
                    size='large'>
                    <ChevronRight />
                </IconButton>
            </Grid>
        </Grid>
    )
}
