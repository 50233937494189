import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, Fade, GlobalStyles } from '@mui/material'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
    HashRouter as Router, Redirect, Route, Switch
} from "react-router-dom"
import { ProvideAuth, useAuth } from './hooks/useAuth'
import { ProvideFleet } from './hooks/useFleet'
import { ProvidePreferences } from './hooks/usePreferences'
import Activities from './pages/Activities'
import Fleet from './pages/Fleet'
import FleetMap from './pages/FleetMap'
import Login from './pages/Login'
import Messages from './pages/Messages'
import Password from './pages/Password'
import Reset from './pages/Reset'
import Trips from './pages/Trips'
import flavorProvider from './services/FlavorProvider'
import { ReactQueryDevtools } from 'react-query/devtools'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60,
        },
    },
})

/* material-ui theme generation */
function App(props) {
    const { t } = useTranslation()
    const flavor = flavorProvider.getTheme()
    const theme = flavor ? createTheme(flavor) : createTheme()
    return (
        <QueryClientProvider client={queryClient}>
            <ProvideAuth>
                <ProvidePreferences>
                    <ProvideFleet>
                        <SnackbarProvider
                            dense
                            maxSnack={5}
                            TransitionComponent={Fade}
                            anchorOrigin={{
                                horizontal: 'left',
                                vertical: 'top',
                            }}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={theme}>
                                    <Helmet>
                                        <title>{t([`${flavorProvider.getName()}:title`, 'title'])}</title>
                                        <link rel="icon" type="image/png" href={flavorProvider.getFavIcon()} sizes="16x16" />
                                        <link rel="manifest" href={flavorProvider.getManifest()} />
                                    </Helmet>
                                    <CssBaseline />
                                    <GlobalStyles
                                        styles={{
                                            // global scrollbar style
                                            '&::-webkit-scrollbar': {
                                                width: '0.4em',
                                                height: '0.4em',
                                            },
                                            '&::-webkit-scrollbar-track': {
                                                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,.1)',
                                            },
                                            // global datepicker style
                                            '.PrivatePickersToolbar-root': {
                                                backgroundColor: theme.palette.primary.main,
                                            },
                                            '.MuiPickersDay-root:focus.Mui-selected': {
                                                backgroundColor: theme.palette.primary.main,
                                            },
                                            '.PrivateDatePickerToolbar-penIcon': {
                                                visibility: 'hidden',
                                            },
                                        }}
                                    />
                                    <Router>
                                        <Switch>
                                            <Route path='/login' component={Login} />
                                            <Route exact path='/reset' component={Reset} />
                                            <Route exact path='/password' component={Password} />
                                            <PrivateRoute path='/trips/:id?/:date?' component={Trips} />
                                            <PrivateRoute path='/activities/:id?/:date?' component={Activities} />
                                            <PrivateRoute path='/messages/:id?/:date?' component={Messages} />
                                            <PrivateRoute exact path='/' component={Fleet} />
                                            <PrivateRoute exact path='/fleet' component={FleetMap} />
                                            <Redirect from='*' to='/' />
                                        </Switch>
                                    </Router>
                                </ThemeProvider>
                            </StyledEngineProvider>
                        </SnackbarProvider>
                    </ProvideFleet>
                </ProvidePreferences>
            </ProvideAuth>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

/* wrapper for <Route> that redirects to login if not authenticated */
const PrivateRoute = ({ component: Component, ...rest }) => {
    const auth = useAuth()
    return (
        <Route {...rest} render={(props) => (
            auth.session ?
                auth.session.mustChangePassword ?
                    <Redirect to={{
                        pathname: '/password',
                        state: { from: props.location }
                    }} /> :
                    <Component {...props} /> :
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
        )} />
    )
}

/* export to access other files */
export default App