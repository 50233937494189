const rietveldTheme = {
	palette: {
		primary: {
			main: '#305582',
		},
		secondary: {
			main: '#e2071b',
		},
	},
};
export default rietveldTheme;