import { Menu as MenuIcon } from '@mui/icons-material'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import React, { useRef } from 'react'
import { useIsFetching } from 'react-query'
import { withRouter } from 'react-router-dom'
import BaseLayout from './BaseLayout'
import Menu from './Menu'
import TitleBar from './TitleBar'

const ContentBox = styled(Box)(({ theme }) => ({
	flex: '1 1 auto',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	overflow: 'hidden',
}))

function DialogLayout({ title, extra, children }) {
	const isFetching = useIsFetching()
	const menuRef = useRef(null)

	const openMenu = (event) => {
		menuRef.current?.setMenuAnchor(event.currentTarget)
	}

	return (
		<BaseLayout>
			<TitleBar
				title={title}
				extra={extra}
				icon={
					<IconButton edge='start' color='inherit' onClick={openMenu} size='large'>
						{isFetching ? <CircularProgress color='inherit' size={24} /> : <MenuIcon />}
					</IconButton>
				}
				menu={<Menu ref={menuRef} />} />
			<ContentBox>
				{children}
			</ContentBox>
		</BaseLayout>
	)
}

export default withRouter(DialogLayout)