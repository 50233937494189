import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import i18n from '../services/i18n'

export default function LanguageButtonGroup() {
    return (
        <ToggleButtonGroup
            value={i18n.language}
            exclusive
            onChange={(e, v) => i18n.changeLanguage(v)}
            aria-label="Language"
            sx={{ alignSelf: 'flex-end' }}>
            <ToggleButton value="de" aria-label="Deutsch">de</ToggleButton>
            <ToggleButton value="en" aria-label="English">en</ToggleButton>
            <ToggleButton value="nl" aria-label="Nederlands">nl</ToggleButton>
        </ToggleButtonGroup>
    )
}
