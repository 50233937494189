import { faCircle, faLocationArrow } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import L from 'leaflet'
import { useEffect, useRef } from 'react'
import { Marker, useMap } from "react-leaflet"
import { usePreferences } from "../hooks/usePreferences"
import { createIcon } from './Map'

export default function StatusMarker({ status, onClick, children }) {
    const preferences = usePreferences()
    const [mapMarkerSize] = preferences.mapMarkerSize
    const map = useMap()
    const ref = useRef(null)

    useEffect(() => {
        L.DomEvent.on(ref.current, 'click', L.DomEvent.stopPropagation)
        L.DomEvent.on(ref.current, 'mousedown', L.DomEvent.stopPropagation)
        L.DomEvent.on(ref.current, 'touchstart', L.DomEvent.stopPropagation)
    })

    // rood = ign - off, green = on, orange = idling
    const orange = '#F9B200'
    const green = '#1A6900'
    const red = '#F40B00'
    const color = status.ignitionState === 'IGNITION_ON' ? status.speed > 0 ? green : orange : red
    const icon = status.ignitionState === 'IGNITION_ON' && status.speed > 0 ? faLocationArrow : faCircle
    const faIcon = <FontAwesomeIcon
        icon={icon}
        transform={{ rotate: status.direction - 45 }}
        style={{
            fontSize: `${mapMarkerSize}px`,
            position: 'relative',
            left: `${mapMarkerSize / -2}px`,
            top: `${mapMarkerSize / -2}px`,
            color: `${color}`,
        }} />

    return (
        <Marker
            ref={ref}
            riseOnHover
            position={[status.position.latitude, status.position.longitude]}
            icon={createIcon(faIcon)}
            eventHandlers={{
                click: () => onClick?.(map, status),
            }}>
            {children}
        </Marker>
    )
}
