import { dateToISOString } from './DateUtils';
import req from './RequestService';

const MessageService = {
    getForVehicle: (vehicleId, start, end, options) => req.get(`vehicles/${vehicleId}/messages?limit=500&start=${dateToISOString(start)}&end=${dateToISOString(end)}`, options),
    getUnread: (vehicleIds, since, options) => req.get(`messages/unread?limit=500${vehicleIds?.length ? `&ids=${vehicleIds.join(',')}` : ''}${since ? `&since=${dateToISOString(since)}` : ''}`, options),
    messageDelivered: (messageId, options) => req.post(`messages/${messageId}/delivered`, options),
    messagesDelivered: (messageIds, options) => req.post(`messages/delivered?ids=${messageIds.join(',')}`, options),
    messageRead: (messageId, options) => req.post(`messages/${messageId}/read`, options),
    messagesRead: (messageIds, options) => req.post(`messages/read?ids=${messageIds.join(',')}`, options),
    sendMessage: (vehicleId, message, options) => req.post(`messages/${vehicleId}/send`, { message: message }, options),
}
export default MessageService;
