import { Box, Button, CircularProgress } from '@mui/material'

export default function ({ disabled, submitting, children }) {
    return (
        <Box sx={{ position: 'relative' }}>
            <Button
                type='submit'
                fullWidth
                sx={{ my: 2 }}
                variant='contained'
                color='primary'
                disabled={disabled || submitting}>
                {children}
            </Button>
            {(submitting !== undefined ? submitting : disabled) &&
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            }
        </Box>
    )
}
