import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { epochToString, toDurationString } from '../services/DateUtils'
import BaseDialog from './BaseDialog'
import NoResults from './NoResults'

export default function BudgetDialog({ open, toggle, status }) {
    const { t } = useTranslation()
    const { driverBudget: budget } = status
    const humanize = value => toDurationString(value * 60000, { units: ['h', 'm'] }, 60000)
    return (
        <BaseDialog title='budget.title' open={open} toggle={toggle}>
            {budget.errorCode ?
                <NoResults text='budget.notAvailable' /> :
                <Stack width={1} padding={2} spacing={.5}>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.driver')}</Typography>
                        <Typography>{budget.name ? budget.name : t('budget.notAvailable')}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.driverStatus')}</Typography>
                        <Typography>{budget.driverStatus ? t(`budget.driverStatus.${budget.driverStatus}`) : '-'}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.startOfActivityShift')}</Typography>
                        <Typography>{epochToString(budget.dayStartTime)}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.endOfDayBreak')}</Typography>
                        <Typography>{epochToString(budget.endOfDayBreak)}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={.5}>
                        <Stack width={.5}>
                            <Typography variant='body2' color='textSecondary'>{t('budget.remainingDrivingTime')}</Typography>
                            <Typography>{humanize(budget.remainingDailyDrivingTime)}</Typography>
                        </Stack>
                        <Stack width={.5}>
                            <Typography variant='body2' color='textSecondary'>{t('budget.remainingWorkingTime')}</Typography>
                            <Typography>{humanize(budget.remainingWorkingHours)}</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' spacing={.5}>
                        <Stack width={.5}>
                            <Typography variant='body2' color='textSecondary'>{t('budget.drivingTimeMaxUsed')}</Typography>
                            <Typography>{`${humanize(budget.maxDailyDrivingTime)} / ${humanize(budget.todaysDailyDrivenTime)}`}</Typography>
                        </Stack>
                        <Stack width={.5}>
                            <Typography variant='body2' color='textSecondary'>{t('budget.workingTimeMaxUsed')}</Typography>
                            <Typography>{`${humanize(budget.maxTotalDayWorktime)} / ${humanize(budget.worktimeActDay)}`}</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.breakBefore')}</Typography>
                        <Typography>{epochToString(budget.nextRestPeriodBefore)}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.minDurationNextBreak')}</Typography>
                        <Typography>{humanize(budget.minDurationNextBreak)}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={.5}>
                        <Stack width={.5}>
                            <Typography variant='body2' color='textSecondary'>{t('budget.remainingWeeklyDrivingTime')}</Typography>
                            <Typography>{humanize(budget.thisWeekDrivingBudget)}</Typography>
                        </Stack>
                        <Stack width={.5}>
                            <Typography variant='body2' color='textSecondary'>{t('budget.remainingWeeklyWorkingTime')}</Typography>
                            <Typography>{humanize(budget.remainingWeeklyWorkingTime)}</Typography>
                        </Stack>
                    </Stack>
                    <Stack>
                        <Typography variant='body2' color='textSecondary'>{t('budget.minDurationNextWeeklyRestPeriod')}</Typography>
                        <Typography>{humanize(budget.minDurationNextWeeklyRestPeriod)}</Typography>
                    </Stack>
                </Stack>
            }
        </BaseDialog >
    )
}
