import olisaTheme from '../themes/olisa'
import arealcontrolTheme from '../themes/arealcontrol'
import linqoTheme from '../themes/linqo'
import rietveldTheme from '../themes/rietveld'
import trasecTheme from '../themes/trasec'

const FlavorProvider = () => {
	const domain = /^(?:.*\.)*(.*)(?:\..*?)$/.exec(window.location.hostname || process.env.REACT_APP_BASE_REST)
	const flavor = domain ? domain[1] : null

	const getTheme = () => {
		switch (flavor) {
			case 'olisa':
				return olisaTheme
			case 'arealcontrol':
				return arealcontrolTheme
			case 'linqo':
				return linqoTheme
			case 'easytrack':
				return rietveldTheme
			case 'trasec':
				return trasecTheme
			default:
				return null
		}
	}
	const getFavIcon = () => {
		return ['olisa', 'arealcontrol', 'easytrack', 'linqo', 'trasec'].includes(flavor) ? flavor + '.ico' : 'favicon.ico'
	}
	const getManifest = () => {
		return ['olisa', 'arealcontrol', 'easytrack', 'linqo', 'trasec'].includes(flavor) ? flavor + '.webmanifest' : 'manifest.json'
	}
	const getLogo = () => {
		return ['arealcontrol', 'easytrack', 'trasec'].includes(flavor) ? flavor + '_logo.svg' : null
	}
	const getName = () => flavor

	return {
		getName,
		getTheme,
		getFavIcon,
		getManifest,
		getLogo,
	}
}

export default FlavorProvider()