import { useTheme } from '@emotion/react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { BsCheck, BsCheckAll, BsX } from 'react-icons/bs';
import { dateToString } from '../services/DateUtils';
import Attachments from './Attachments';


const useStyles = makeStyles(theme => ({
    bubble: {
        width: "100%",
        display: "inline-block",
        borderRadius: theme.spacing(1.5),
        margin: theme.spacing(1, 1.5),
    },
    outgoingMine: {
        backgroundColor: theme.palette.grey.A200,
        borderColor: theme.palette.grey.A200,
        borderBottomRightRadius: theme.spacing(.2),
    },
    outgoingOther: {
        backgroundColor: theme.palette.grey.A400,
        borderColor: theme.palette.grey.A400,
        borderBottomLeftRadius: theme.spacing(.2),
    },
    incoming: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        borderBottomLeftRadius: theme.spacing(.2),
    },
    content: {
        padding: theme.spacing(1, 1.5),
        '&:last-child': {
            paddingBottom: theme.spacing(1),
        },
    },
    secondary: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    text: {
        whiteSpace: 'pre-line',
    },
}));

const Message = ({ message }) => {
    const classes = useStyles();
    const theme = useTheme();

    const stateIcon = () => {
        switch (message.messageState) {
            case 'SENT':
            case 'RECEIVED':
                return <BsCheck fontSize="large" />
            case 'PARTLY_DELIVERED':
            case 'DELIVERED':
                return <BsCheckAll fontSize="large" />
            case 'PARTLY_READ':
                let receiver = message.receivers.find(r => r.isMine)
                if (receiver)
                    return (receiver.messageInstanceState === 'READ' || receiver.messageInstanceState === 'REPLIED_TO') ?
                        <BsCheckAll fontSize="large" color={theme.palette.info.main} /> :
                        <BsCheckAll fontSize="large" />
                else
                    return <BsCheckAll fontSize="large" />
            case 'READ':
            case 'REPLIED_TO':
                return <BsCheckAll fontSize="large" color={theme.palette.info.main} />
            case 'CLOSED':
                return <BsX fontSize="large" color={theme.palette.info.main} />
            case 'FAILED':
                return <BsX fontSize="large" color={theme.palette.error.main} />
        }
    }

    return (
        <Card className={`${classes.bubble} ${message.isMine ? classes.outgoingMine : message.sendingAsset ? classes.incoming : classes.outgoingOther}`}>
            <CardContent className={classes.content}>
                <Grid container alignItems="center">
                    <Grid item xs />
                    <Typography variant="subtitle2" className={classes.secondary}>
                        {dateToString(message.sent)}
                    </Typography>
                    {stateIcon()}
                </Grid>
            </CardContent>
            <CardContent className={classes.content}>
                <Typography className={classes.text}>
                    {message.content}
                </Typography>
                {message.attachmentReferences &&
                    <Attachments attachmentReferences={message.attachmentReferences} />
                }
            </CardContent>
        </Card>
    );
};

export default Message;