
export const driverToString = (driver) => {
	return driver ? [driver.lastName, driver.firstName].filter(Boolean).join(', ') : null;
}

export const vehicleToString = (vehicle) => {
	return vehicle.name + ((vehicle.make || vehicle.model) ? ' (' + [vehicle.make, vehicle.model].filter(Boolean).join(', ') + ')' : '');
}

export const addressToString = (address) => {
	return [address.street, address.city, address.country].filter(Boolean).join(', ');
}

export const distanceToString = (end, start) => {
	return (Math.round((end - start) * 100) / 100).toFixed(2) + ' km';
}

export const hashCode = str => Array.from(str).reduce((a, b) => { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0)
