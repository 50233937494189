import { Logout } from "@mui/icons-material"
import { ListItemIcon, Menu, MenuItem } from "@mui/material"
import { forwardRef, useImperativeHandle, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { useAuth } from "../hooks/useAuth"
import LanguageButtonGroup from "./LanguageButtonGroup"

export default forwardRef((props, ref) => {
    const { children } = props
    const { t } = useTranslation()
    const auth = useAuth()
    const queryClient = useQueryClient()
    const history = useHistory()
    const [menuAnchor, setMenuAnchor] = useState(null)

    useImperativeHandle(ref, () => ({
        setMenuAnchor,
    }))

    const closeMenu = () => {
        setMenuAnchor(null)
    }

    const signOut = () => {
        auth.signOut().then(() => {
            queryClient.removeQueries()
            history.push("/login", history.currentTarget)
        })
    }

    return (
        <Menu
            keepMounted
            anchorEl={menuAnchor}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={!!menuAnchor}
            onClose={closeMenu}
            onClick={closeMenu}>
            <MenuItem>
                <LanguageButtonGroup />
            </MenuItem>
            {children}
            <MenuItem
                onClick={signOut}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                {t('menu.signout')}
            </MenuItem>
        </Menu>
    )
})