import L from 'leaflet'
import { useEffect, useRef } from 'react'
import { Marker, useMap } from "react-leaflet"
import { createIcon } from './Map'

export default function MapMarker({ icon, position, onClick, children }) {
    const map = useMap()
    const ref = useRef(null)

    useEffect(() => {
        L.DomEvent.on(ref.current, 'click', L.DomEvent.stopPropagation)
        L.DomEvent.on(ref.current, 'mousedown', L.DomEvent.stopPropagation)
        L.DomEvent.on(ref.current, 'touchstart', L.DomEvent.stopPropagation)
    })

    return (
        <Marker
            ref={ref}
            riseOnHover
            position={position}
            icon={createIcon(icon)}
            eventHandlers={{
                click: () => onClick?.(map),
            }}>
            {children}
        </Marker>
    )
}
