import React from 'react'
import { Divider, Grid, Typography } from "@mui/material"

import Attachments from './Attachments'

export default function Questions({ questionnaire }) {
    return (
        <Grid>
            <Divider />
            {questionnaire.questions &&
                questionnaire.questions.filter(q => q.values || q.references).map((question, questionIndex) => <Grid key={questionIndex}>
                    <Typography variant="caption" color="textSecondary">{question.questionLabel}</Typography>
                    {question.values &&
                        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{question.values.join(', ')}</Typography>}
                    {question.references &&
                        <Attachments attachmentReferences={question.references} />}
                </Grid>
                )}
        </Grid>
    )
}
