import { ClearAll } from '@mui/icons-material'
import { Chip, Grid, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'
import { useFleet } from '../hooks/useFleet'
import BaseDialog from './BaseDialog'

const ScrollGrid = styled(Grid)(({ theme }) => ({
    width: '100%',
    overflow: 'auto',
}))

const ChipGrid = styled(Grid)(({ theme }) => ({
    width: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
        margin: theme.spacing(0.5),
    },
}))

export default function VehiclesSelector({ open, toggle }) {
    const fleet = useFleet()

    let byName = (a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)

    return (
        <BaseDialog
            open={open}
            toggle={toggle}
            title='Select Vehicles'
            extra={<IconButton color="inherit" onClick={fleet.toggleVehicles} size="large"><ClearAll /></IconButton>}>
            <ScrollGrid>
                <ChipGrid>
                    {fleet.groups
                        .sort(byName).map(g =>
                            <Chip key={g.id}
                                label={g.name}
                                onClick={() => fleet.toggleGroup(g)}
                                color='secondary'
                                variant={g.selected ? 'default' : 'outlined'} />)}
                </ChipGrid>
                <ChipGrid>
                    {fleet.vehicles
                        .filter(v => fleet.groups.every(g => !g.selected) || fleet.groups.filter(g => g.selected).some(g => g.assetIds && g.assetIds.includes(v.id)))
                        .sort(byName).map(v =>
                            <Chip key={v.id}
                                label={v.name}
                                onClick={() => fleet.toggleVehicle(v)}
                                color={v.selected ? 'primary' : 'default'} />)
                    }
                </ChipGrid>
            </ScrollGrid>
        </BaseDialog>
    )
}