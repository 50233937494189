import { Assignment, DirectionsCar, ExpandMore, QuestionAnswer } from '@mui/icons-material'
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import {
    Badge, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography
} from '@mui/material'
import { styled } from '@mui/styles'
import { formatISO, isSameDay, parseISO } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'
import { durationToString } from '../services/DateUtils'
import { addressToString, distanceToString, driverToString } from '../services/StringUtils'
import Questions from './Questions'


const ActivityHeader = styled(CardHeader)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
}))

const RotateButton = styled(IconButton)(({ angle, theme }) => ({
    color: theme.palette.primary.contrastText,
    transform: `rotate(${angle}deg)`,
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default function Activity({ time, activity, lastItem }) {
    const { t } = useTranslation()
    const { value: expanded, toggle: toggleExpanded } = useBoolean(false)

    const countQuestions = (count, questionnaire) => count + questionnaire.questions.filter(q => q.values || q.references).length
    const questionCount = (activity.questionnaires && activity.questionnaires.some(q => q.questions) ? activity.questionnaires.reduce(countQuestions, 0) : 0) +
        (activity.task && activity.task.questionnaires && activity.task.questionnaires.some(q => q.questions) ? activity.task.questionnaires.reduce(countQuestions, 0) : 0)
    const hasQuestions = questionCount > 0

    const getCardSubheader = () => {
        let duration = durationToString(activity.start, activity.end)
        if (activity.activityTypeIsTravelTime && activity.totalDistanceEnd) {
            let distance = distanceToString(activity.totalDistanceEnd, activity.totalDistanceStart)
            return `${duration} / ${distance}`
        }
        else
            return `${duration}`
    }

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ alignSelf: 'center', flex: 0.1 }}>
                <Typography variant="body2" color="textSecondary">{time}</Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
                {activity.end && isSameDay(parseISO(activity.start), parseISO(activity.end)) ?
                    <TimelineConnector /> :
                    <TimelineConnector sx={{ bgcolor: 'background.default', borderLeft: '2px dashed', borderColor: 'grey.400' }} />
                }
                <TimelineDot onClick={hasQuestions ? toggleExpanded : null} sx={{ bgcolor: `${activity.activityTypeColor}` }}>
                    <Badge color="primary" badgeContent={questionCount} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                        {hasQuestions ? <QuestionAnswer /> : activity.activityTypeIsTravelTime ? <DirectionsCar /> : <Assignment />}
                    </Badge>
                </TimelineDot>
                {lastItem ?
                    <TimelineConnector sx={{ bgcolor: 'background.default' }} /> :
                    <TimelineConnector />
                }
            </TimelineSeparator>
            <TimelineContent>
                <Card>
                    <ActivityHeader
                        onClick={hasQuestions ? toggleExpanded : null}
                        action={hasQuestions &&
                            <RotateButton angle={expanded ? 180 : 0} size='large'>
                                <ExpandMore />
                            </RotateButton>
                        }
                        titleTypographyProps={{ variant: 'h6' }}
                        title={activity.label}
                        subheader={<Typography>{getCardSubheader()}</Typography>}
                    />
                    <CardContent>
                        {activity.startAddress &&
                            <Grid>
                                <Typography variant="caption" color="textSecondary">{activity.activityTypeIsTravelTime ? t("Departure") : t("Location")}</Typography>
                                <Typography variant="body1">{addressToString(activity.startAddress)}</Typography>
                            </Grid>
                        }
                        {(activity.activityTypeIsTravelTime && activity.endAddress) &&
                            <Grid>
                                <Typography variant="caption" color="textSecondary">{t("Arrival")}</Typography>
                                <Typography variant="body1">{addressToString(activity.endAddress)}</Typography>
                            </Grid>
                        }
                        {activity.person &&
                            <Grid>
                                <Typography variant="caption" color="textSecondary">{t("Driver")}</Typography>
                                <Typography variant="body1">{driverToString(activity.person)}</Typography>
                            </Grid>
                        }
                        {hasQuestions &&
                            <Collapse in={expanded} timeout='auto' unmountOnExit sx={{ pt: 2 }}>
                                {activity.questionnaires &&
                                    activity.questionnaires.map((q, i) => <Questions key={i} questionnaire={q} />)}
                                {(activity.task && activity.task.questionnaires) &&
                                    activity.task.questionnaires.map((q, i) => <Questions key={i} questionnaire={q} />)}
                            </Collapse>
                        }
                    </CardContent>
                </Card>
            </TimelineContent>
        </TimelineItem>
    )
}

