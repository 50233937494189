import req from './RequestService';
import { dateToISOString } from './DateUtils';

const TrackService = {
    getForDriver: (driverId, start, end, options) => req.get(`persons/${driverId}/tracks?start=${dateToISOString(start)}&end=${dateToISOString(end)}&limit=500`, options),
    getForVehicle: (vehicleId, start, end, options) => req.get(`vehicles/${vehicleId}/tracks?start=${dateToISOString(start)}&end=${dateToISOString(end)}&limit=500`, options),
    update: (trackId, newValues, options) => req.post(`tracks/${trackId}/check`, newValues, options),
    get: (trackId, options) => req.get(`tracks/${trackId}`, options),
    getClosestForDriver: (driverId, date, operation, options) => req.get(`persons/${driverId}/closestTrack?date=${dateToISOString(date)}&operator=${operation}`, options),
    getClosestForVehicle: (vehicleId, date, operation, options) => req.get(`vehicles/${vehicleId}/closestTrack?date=${dateToISOString(date)}&operator=${operation}`, options),
    getPositions: (trackId, options) => req.get(`tracks/${trackId}/positions`, options),
    getSummaryForDriver: (driverId, year, month, options) => req.get(`tracks/summary/${driverId}/${year}/${month}`, options),
}
export default TrackService;
