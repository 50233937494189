import { Clear, Email, FilterList, Search } from '@mui/icons-material'
import {
	Badge,
	Box,
	Grid,
	IconButton,
	InputBase,
	List,
	ListItem,
	ListItemText
} from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import { isToday, parseISO, startOfToday } from 'date-fns'
import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { withRouter } from "react-router-dom"
import { useBoolean, useDebounce } from 'usehooks-ts'
import Loading from '../components/Loading'
import NoResults from '../components/NoResults'
import PageLayout from '../components/PageLayout'
import Status from '../components/Status'
import VehiclesSelector from '../components/VehiclesSelector'
import { useAuth } from '../hooks/useAuth'
import { useFleet } from '../hooks/useFleet'
import { usePreferences } from '../hooks/usePreferences'
import useSessionLocalStorage from '../hooks/useSessionLocalStorage'
import MessageService from '../services/MessageService'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	"&:disabled": {
		color: theme.palette.primary.contrastText
	}
}))

const ScrollGrid = styled(Grid)(({ theme }) => ({
	width: '100%',
	overflow: 'auto'
}))

const SearchInput = styled('div')(({ open, theme }) => ({
	position: 'relative',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: open ? alpha(theme.palette.common.white, 0.15) : alpha(theme.palette.common.white, 0),
	'&:hover': open && {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	width: 'auto',
	height: 'fit-content',
}))

const StyledInputBase = styled(InputBase)(({ open, theme }) => ({
	color: 'inherit',
	'& .MuiInputBase-input': {
		padding: theme.spacing(1, 1, 1, 1),
		alignItems: 'center',
		transition: theme.transitions.create('width'),
		width: open ? '16ch' : 0,
	},
}))

function Fleet() {
	const { t } = useTranslation()
	const auth = useAuth()
	const preferences = usePreferences()
	const fleet = useFleet()

	const { value: dialogOpen, toggle: toggleDialog } = useBoolean(false)

	const filterInput = useRef(null)
	const [filterOpen, setFilterOpen] = useSessionLocalStorage('Fleet.filterOpen', false)
	const [filterText, setFilterText] = useSessionLocalStorage('Fleet.filterText', '')
	const debouncedFilterText = useDebounce(filterText, 500)

	const selectedFleetFields = preferences.selectedFleetFields
	const [denseFields] = preferences.fleetDenseFields

	const queryClient = useQueryClient()
	const controller = new AbortController()

	const { data: status, isLoading: statusIsLoading } = useQuery(['fleet.status', ...fleet.selectedVehicleIds], async () => {
		const response = await fleet.getVehicleStatus(fleet.selectedVehicleIds, { signal: controller.signal })
		return fleet.selectedVehicleIds.map(s => response.find(r => r.vehicle.id === s) || { vehicle: fleet.vehicles.find(v => v.id === s) })
	}, {
		enabled: !!fleet.selectedVehicleIds.length,
		refetchInterval: 60 * 1000,
	})

	const { data: unread } = useQuery(['unread', ...fleet.selectedVehicleIds], async () => {
		const response = await MessageService.getUnread(fleet.selectedVehicleIds, startOfToday(), { signal: controller.signal })
		// use only todays messages (until rfc for variable period becomes priority)
		return response.filter(m => fleet.selectedVehicleIds.includes(m.sendingAsset.id)).filter(m => isToday(parseISO(m.sent)))
	}, {
		enabled: !!fleet.selectedVehicleIds.length && auth.hasPrivilege('VIEW_MESSAGES'),
		refetchInterval: 60 * 1000,
	})

	const messagesDelivered = useMutation(messages => MessageService.messagesDelivered(messages.map(m => m.id)), {
		onSuccess: data => {
			queryClient.setQueryData(['unread', ...fleet.selectedVehicleIds], unread.map(m => data.find(d => d.id === m.id) ?? m))
		},
	})

	useEffect(() => {
		let undelivered = unread?.filter(m => m.receivers.find(r => r.isMine && !r.delivered))
		if (undelivered?.length)
			messagesDelivered.mutate(undelivered)
	}, [unread])

	useEffect(() => {
		return () => controller.abort()
	}, [])

	const searchStrings = useMemo(() => {
		return status?.map(s => {
			let strings = []
			selectedFleetFields.forEach(f => {
				switch (f.name) {
					case 'vehicle':
						strings = [...strings, s.vehicle.name, s.vehicle?.make, s.vehicle?.model, s?.driver?.firstName, s?.driver?.lastName]
						break
					case 'driver':
						strings = [...strings, s.vehicle.name, s.vehicle?.make, s.vehicle?.model, s?.driver?.firstName, s?.driver?.lastName]
						break
					case 'position':
						strings = [...strings, s?.position?.street, s?.position?.city, s?.position?.country, s?.location]
						break
					case 'activity':
						strings = [...strings, s?.activityStatus?.name]
						break
					case 'destination':
						strings = [...strings, s?.etaStatus?.address?.street, s?.etaStatus?.address?.city, s?.etaStatus?.address?.country]
						break
					case 'task':
						strings = [...strings, s?.activityStatus?.task, s?.activityStatus?.taskLocation, s?.activityStatus?.taskTrip]
						break
					case 'online':
						if (!s?.online)
							strings = [...strings, t(s.online ? 'Online' : 'Offline')]
						break
					case 'ignition':
						if (s.ignitionState && s.ignitionState !== 'UNKNOWN')
							strings = [...strings, `${t('Ignition')} ${t(s.ignitionState)}`]
						break
					case 'budget':
						if (s.driverBudget && s.driverBudget.errc)
							strings = [...strings, t('budget.notAvailable')]
						break
					case 'messages':
						let content = unread?.filter(m => s.vehicle.id === m.sendingAsset.id).map(m => m.content)
						if (content)
							strings = [...strings, ...content]
						break
				}
			})
			return { id: s.vehicle.id, strings: strings.filter(s => s !== undefined).map(s => s.toLowerCase()) }
		})
	}, [status, selectedFleetFields, unread])

	useEffect(() => {
		if (filterOpen)
			filterInput.current.focus()
	}, [filterOpen])

	const toggleFilter = () => {
		setFilterOpen(!filterOpen)
	}

	const applyFilter = status => {
		return debouncedFilterText === '' ||
			debouncedFilterText.toLowerCase().split(/[ ,]+/).every(f =>
				searchStrings?.find(s => s.id === status.vehicle.id)['strings']?.some(s => s.includes(f))
			)
	}

	const filteredStatus = useMemo(() => filterOpen ? status?.filter(applyFilter) : status, [filterOpen, debouncedFilterText, status])

	const byVehicleName = (a, b) => (a.vehicle.name > b.vehicle.name) ? 1 : ((b.vehicle.name > a.vehicle.name) ? -1 : 0)

	return (
		<PageLayout title='fleet.title' extra={
			<Box sx={{
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
				gap: 1,
			}}>
				<SearchInput open={filterOpen}>
					<StyledInputBase open={filterOpen} placeholder={t('fleet.search')} value={filterText} onChange={event => setFilterText(event.target.value)}
						inputRef={filterInput}
						endAdornment={
							<StyledIconButton onClick={toggleFilter}>
								{filterOpen ?
									<Clear /> :
									<Search />
								}
							</StyledIconButton>
						}
					/>
				</SearchInput>
				{unread?.length > 0 &&
					<StyledIconButton disabled size="large">
						<Badge color="secondary" badgeContent={unread.length}>
							<Email />
						</Badge>
					</StyledIconButton>
				}
				<IconButton onClick={toggleDialog} color="inherit" size="large">
					<FilterList />
				</IconButton>
			</Box>}>
			{statusIsLoading ?
				<Loading /> :
				status?.length ?
					filteredStatus?.length ?
						<ScrollGrid>
							<List disablePadding dense={denseFields}>
								{filteredStatus.sort(byVehicleName).map(s => (
									<ListItem key={s.vehicle.id} button divider disablePadding disableGutters>
										<ListItemText>
											<Status status={s} messages={unread?.filter(m => s.vehicle.id === m.sendingAsset.id)} />
										</ListItemText>
									</ListItem>
								))}
							</List>
						</ScrollGrid> :
						<NoResults text='fleet.noMatches' /> :
					<NoResults text='fleet.noVehiclesSelected' />
			}
			<VehiclesSelector open={dialogOpen} toggle={toggleDialog} />
		</PageLayout>
	)
}

export default withRouter(Fleet)