import { Email, FilterList, Label, LabelOffOutlined, Lock, LockOpen } from '@mui/icons-material'
import { Badge, IconButton } from '@mui/material'
import { styled } from '@mui/styles'
import { isToday, parseISO, startOfToday } from 'date-fns'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { withRouter } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import BottomCollapse from '../components/BottomCollapse'
import Map, { determineBounds } from '../components/Map'
import PageLayout from '../components/PageLayout'
import Status from '../components/Status'
import StatusMarker from '../components/StatusMarker'
import StatusTooltip from '../components/StatusTooltip'
import VehiclesSelector from '../components/VehiclesSelector'
import { useAuth } from '../hooks/useAuth'
import { useFleet } from '../hooks/useFleet'
import { usePreferences } from '../hooks/usePreferences'
import MessageService from '../services/MessageService'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    '&:disabled': {
        color: theme.palette.primary.contrastText,
    }
}))

function FleetMap() {
    const auth = useAuth()
    const preferences = usePreferences()
    const fleet = useFleet()
    const [mapLabelsEnabled] = preferences.mapLabelsEnabled
    const { value: dialogOpen, toggle: toggleDialog } = useBoolean(false)
    const { value: zoomToFit, setFalse: disableZoomToFit, toggle: toggleZoomToFit } = useBoolean(true)
    const { value: showTooltip, toggle: toggleShowTooltip } = useBoolean(mapLabelsEnabled)
    const { value: showDetails, setFalse: hideDetails, toggle: toggleDetails } = useBoolean(false)
    const [details, setDetails] = useState(null)

    const queryClient = useQueryClient()
    const controller = new AbortController()

    const { data: status } = useQuery(['map.status', ...fleet.selectedVehicleIds], async () => {
        const response = await fleet.getVehicleStatus(fleet.selectedVehicleIds, { signal: controller.signal })
        return response.filter(r => r.position && r.position.latitude && r.position.longitude)
    }, {
        enabled: !!fleet.selectedVehicleIds.length,
        refetchInterval: 60 * 1000,
    })

    const { data: unread } = useQuery(['unread', ...fleet.selectedVehicleIds], async () => {
        const response = await MessageService.getUnread(fleet.selectedVehicleIds, startOfToday(), { signal: controller.signal })
        // use only todays messages (until rfc for variable period becomes priority)
        return response.filter(m => fleet.selectedVehicleIds.includes(m.sendingAsset.id)).filter(m => isToday(parseISO(m.sent)))
    }, {
        enabled: !!fleet.selectedVehicleIds.length && auth.hasPrivilege('VIEW_MESSAGES'),
        refetchInterval: 60 * 1000,
    })

    const messagesDelivered = useMutation(messages => MessageService.messagesDelivered(messages.map(m => m.id)), {
        onSuccess: data => {
            queryClient.setQueryData(['unread', ...fleet.selectedVehicleIds], unread.map(m => data.find(d => d.id === m.id) ?? m))
        },
    })

    useEffect(() => {
        let undelivered = unread?.filter(m => m.receivers.find(r => r.isMine && !r.delivered))
        if (undelivered?.length)
            messagesDelivered.mutate(undelivered)
    }, [unread])

    useEffect(() => {
        return () => controller.abort()
    }, [])

    useEffect(() => {
        if (!fleet.selectedVehicleIds.includes(details?.vehicle?.id)) {
            setDetails(null)
            hideDetails()
        }
    }, [fleet.selectedVehicleIds])

    const bounds = useMemo(() => status?.length ? determineBounds(status?.map(s => [s.position.latitude, s.position.longitude])) : null, [status])

    return (
        <PageLayout title='map.title' extra={
            <Fragment>
                {unread?.length > 0 &&
                    <StyledIconButton disabled size='large'>
                        <Badge color='secondary' badgeContent={unread.length}>
                            <Email />
                        </Badge>
                    </StyledIconButton>
                }
                <IconButton color='inherit' onClick={toggleShowTooltip} size='large'>
                    {showTooltip ?
                        <Label /> :
                        <LabelOffOutlined />}
                </IconButton>
                <IconButton color='inherit' onClick={toggleZoomToFit} size='large'>
                    {zoomToFit ?
                        <Lock /> :
                        <LockOpen />}
                </IconButton>
                <IconButton color='inherit' onClick={toggleDialog} size='large'>
                    <FilterList />
                </IconButton>
            </Fragment>}>
            {bounds &&
                <Map zoomToFit={zoomToFit}
                    eventHandlers={{
                        click: hideDetails,
                        mousedown: disableZoomToFit,
                        touchstart: disableZoomToFit,
                    }}
                    bounds={bounds}>
                    {status?.map(s =>
                        <StatusMarker
                            key={s.vehicle.code}
                            status={s}
                            onClick={(m, s) => {
                                setDetails(s)
                                if (!showDetails || s.vehicle.id === details.vehicle?.id)
                                    toggleDetails()
                            }}>
                            {showTooltip && <StatusTooltip status={s} messages={unread?.filter(m => m.sendingAsset.id === s.vehicle.id)} />}
                        </StatusMarker>)
                    }
                </Map>

            }
            <BottomCollapse open={showDetails}>
                {details &&
                    <Status nested status={details} messages={unread?.filter(m => m.sendingAsset.id === details.vehicle?.id)} />
                }
            </BottomCollapse>
            <VehiclesSelector open={dialogOpen} toggle={toggleDialog} />
        </PageLayout >
    )
}

export default withRouter(FleetMap)