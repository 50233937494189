import React from 'react'
import { Grid, Typography } from "@mui/material";

export default function Timeline({ textColor, textVariant, iconColor, icon, time, text }) {
    textColor = textColor ? textColor : "textPrimary";
    textVariant = textVariant ? textVariant : "body1";
    iconColor = iconColor ? iconColor : "primary";
    return (
        <Grid item container alignItems="center" spacing={1}>
            <Grid item xs={3}>
                <Typography align="right" color={textColor} variant={textVariant}>
                    {time}
                </Typography>
            </Grid>
            <Grid item>
                <Typography color={iconColor}>{icon}</Typography>
            </Grid>
            <Grid item xs>
                <Typography color={textColor} variant={textVariant}>{text}</Typography>
            </Grid>
        </Grid>
    )
}
