import React, { useEffect, useRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));

export default function PolicyDialog({ open, onClose }) {
    const { t } = useTranslation();
    const policyContent = useRef(null);

    useEffect(() => {
        if (open) {
            policyContent.current && policyContent.current.focus();
        }
    }, [open]);

    return (
        <Dialog open={open} scroll="paper">
            <DialogContent>
                <DialogContentText ref={policyContent} tabIndex={-1} component="div">
                    Door akkoord te gaan verklaar ik als EasyTax gebruiker kennis te hebben genomen van de Gedragscode Privacy RRS en het reglement Beroepscommissie.
                    <ul>
                        <li>Klik <a href='https://keurmerkritregistratiesystemen.nl/files/original/170901-gedragscode-privacy-rrs-versie-5.0.pdf' target='_blank'><i>hier</i></a> voor de Privacy gedragscode.</li>
                        <li>Klik <a href='/files/Reglement Beroepscommissie t.b.v. Gedragscode Privacy RRS.pdf' target='_blank'><i>hier</i></a> voor het reglement Beroepscommissie.</li>
                    </ul>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <StyledButton fullWidth onClick={onClose}>
                    {t('Agree')}
                </StyledButton>
            </DialogActions>
        </Dialog>
    )
}
