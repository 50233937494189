import { Checkbox, FormControl, FormControlLabel, Link, Stack, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useHistory, useLocation, withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import BaseLayout from '../components/BaseLayout'
import LanguageButtonGroup from '../components/LanguageButtonGroup'
import Logo from '../components/Logo'
import SubmitButton from '../components/SubmitButton'
import { useAuth } from '../hooks/useAuth'

const ScrollBar = styled(SimpleBar)(({ theme }) => ({
	height: '100%',
	padding: theme.spacing(2),
}))

function Login() {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const auth = useAuth()
	const history = useHistory()
	const location = useLocation()
	const { from } = location.state || { from: { pathname: '/' } }

	const { errors, handleChange, values, handleSubmit, isSubmitting, setSubmitting } = useFormik({
		initialValues: {
			organisation: localStorage.getItem('organisation') || '',
			username: localStorage.getItem('username') || '',
			password: '',
			rememberMe: localStorage.getItem('username') !== undefined
		},
		validateOnChange: false,
		validateOnBlur: false,
		validate() {
			const errors = {}
			if (!values.organisation) {
				errors.organisation = true
			}
			if (!values.username) {
				errors.username = true
			}
			if (!values.password) {
				errors.password = true
			}
			return errors
		},
		onSubmit(values) {
			auth.signIn(values.organisation, values.username, values.password).then(result => {
				if (values.rememberMe) {
					localStorage.setItem('organisation', result.organisationName)
					localStorage.setItem('username', values.username)
				} else {
					localStorage.removeItem('organisation')
					localStorage.removeItem('username')
				}
				setSubmitting(false)
				history.replace(from)
			}).catch(() => {
				enqueueSnackbar(t('error.signIn'), { variant: 'error', style: { whiteSpace: 'pre-line' } })
				setSubmitting(false)
			})
		}
	})

	return (
		<BaseLayout>
			<ScrollBar>
				<Stack alignItems='center'>
					<LanguageButtonGroup />
					<Logo />
					<form onSubmit={handleSubmit} noValidate>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.organisation}
								variant='standard'
								label={t('login.organisation')}
								name='organisation'
								autoFocus
								value={values.organisation}
								autoComplete='organization'
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.username}
								variant='standard'
								label={t('login.username')}
								name='username'
								autoComplete='username'
								value={values.username}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.password}
								variant='standard'
								label={t('login.password')}
								name='password'
								type='password'
								autoComplete='current-password'
								value={values.password}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControlLabel
							control={<Checkbox name='rememberMe' color='default' checked={values.rememberMe} onChange={handleChange} />}
							sx={{ mt: 2 }}
							label={t('login.rememberSession')}
						/>
						<SubmitButton
							disabled={isSubmitting}
							data-testid='signIn'>
							{t('login.signIn')}
						</SubmitButton>
						<Link component={RouterLink} to='/reset'>{t('login.forgotPassword')}</Link>
					</form>
				</Stack>
			</ScrollBar>
		</BaseLayout >
	)
}

export default withRouter(Login)