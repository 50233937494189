import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			en: {
				linqo: {
					title: 'FleetControl',
				},
				arealcontrol: {
					title: 'ArealControl',
				},
				common: {
					title: 'µControl',
					'login.signIn': 'Sign in',
					'menu.signout': 'Sign out',
					'login.organisation': 'Organisation',
					'login.username': 'Username',
					'login.password': 'Password',
					'error.signIn': 'Failed to sign in',
					'login.rememberSession': 'Remember session',
					'password.currentPassword': 'Current password',
					'password.newPassword': 'New password',
					'password.confirmNewPassword': 'Confirm new password',
					'password.changePassword': 'Change password',
					'error.changePassword': 'Failed to change password',
					'login.forgotPassword': 'Forgot password?',
					'reset.email': 'E-mail address',
					'reset.resetPassword': 'Reset Password',
					'error.resetPassword': 'Failed to reset password',
					'Agree': 'Agree',
					Live: 'Live',
					'trips.title': 'Trips',
					'activities.title': 'Activities',
					'messages.title': 'Messages',
					'messages.enterMessage': 'Enter message...',
					'messages.sendError': 'Error sending message',
					'map.trip.title': 'Trip',
					'Trip details': 'Trip details',
					'Location': 'Location',
					'Task': 'Task',
					'checked': 'Checked',
					'menu.forceClosePeriod': 'Force close period',
					'menu.sort': 'Sort',
					'menu.oldestFirst': 'Oldest first',
					'menu.newestFirst': 'Newest first',
					'menu.hideShortTrips': 'Hide short trips',
					'map.vehicle.title': 'Vehicle',
					'Private mode': 'Private',
					'Private kilometers': 'Private Kilometers',
					'Details': 'Details',
					'Retrieving trip': 'Retrieving details...',
					'trips.noTrips': 'No trips',
					'trips.periodClosed': 'Period closed',
					'activities.noActivities': 'No activities',
					'trackType': 'Trip type',
					'reason': 'Reason',
					'Required': 'Required',
					'BUSINESS': 'Business',
					'PRIVATE': 'Private',
					'COMMUTER': 'Commute',
					'Ignition': 'Ignition',
					'IGNITION_ON': 'On',
					'IGNITION_OFF': 'Off',
					'Online': 'Online',
					'Offline': 'Offline',
					'kilometrage': 'Kilometrage',
					'detour': 'Detour',
					'detourDistance': 'Detour distance',
					'detourDescription': 'Description',
					'detourReference': 'Reference',
					'detourTrackType': 'Detour trip type',
					'create': 'Create',
					'update': 'Update',
					'delete': 'delete',
					'Saved': 'Saved',
					'cancel': 'cancel',
					'calendar.today': 'Today',
					'calendar.close': 'Close',
					'Bookings': 'Bookings',
					'Booking': 'Booking',
					'Booking details': 'Booking details',
					'no bookings': 'No bookings',
					'Remove booking?': 'Remove booking?',
					'Now': 'Now',
					'Pick a later date or time': 'Pick a later date or time',
					'End': 'End',
					'End booking?': 'End booking?',
					'map.title': 'Map',
					'budget.title': 'Driver Budget',
					'start': 'start',
					'end': 'end',
					'fleet.title': 'Fleet',
					'fleet.search': 'Search…',
					'since': 'Since',
					'until': 'Until',
					'Last seen': 'Last seen',
					'messages.messageCount_zero': 'No messages',
					'messages.messageCount_one': '{{count}} Message',
					'messages.messageCount_other': '{{count}} Messages',
					'Select Vehicles': 'Select Vehicles',
					'fleet.noVehiclesSelected': 'No vehicles selected',
					'fleet.noMatches': 'No matches',
					'Departure': 'Departure',
					'Arrival': 'Arrival',
					'Remaining driving/working time': 'Remaining driving/working time',
					'PlannedAssetRelation.Asset.wrong.state': 'Booking has been edited remotely',
					'PlannedAssetRelation.start.in.past': 'Start is too far in past',
					'PlannedAssetRelation.not.found': 'Booking not found',
					'PlannedAssetRelation.editing.not.allowed': 'Not allowed to edit (any more)',
					'PlannedAssetRelation.overlap.found': 'A booking already exists in the given range',
					'PlannedAssetRelation.maintentance.window.found': 'There is a maintenance window in the given range',
					'preferences.title': 'Preferences',
					'preferences.fleet.denseFields': 'Compact field text',
					'preferences.fleet.fieldsThreshold': 'Number of fields in the unextended status',
					'preferences.fleet.selectFields': 'Select fleet fields and order',
					'preferences.selectFields.title': 'Select fields',
					'preferences.map.markerSize': 'Map marker size',
					'preferences.map.labelsEnabled': 'Show map labels by default',
					'preferences.map.selectFields': 'Select map label fields and order',
					'preferences.trip.hideShort': 'Hide short trips by default',
					'preferences.trip.distanceThreshold': 'Short trip threshold',
					'preferences.trip.sortOrder': 'Default sort order',
					'preferences.field.label.vehicle': 'Vehicle',
					'preferences.field.summary.vehicle': 'Vehicle/driver name and online/ignition status',
					'preferences.field.label.driver': 'Driver',
					'preferences.field.summary.driver': 'Driver/vehicle name and online/ignition status',
					'preferences.field.label.position': 'Position',
					'preferences.field.summary.position': 'Last known address and location',
					'preferences.field.label.activity': 'Activity',
					'preferences.field.summary.activity': 'Last known driver activity',
					'preferences.field.label.destination': 'Destination',
					'preferences.field.summary.destination': 'Current destination and estimated traveltime',
					'preferences.field.label.task': 'Planning',
					'preferences.field.summary.task': 'Current trip/location/task',
					'preferences.field.label.online': 'Online',
					'preferences.field.summary.online': 'Last known connection status',
					'preferences.field.label.ignition': 'Ignition',
					'preferences.field.summary.ignition': 'Current ignition status and speed',
					'preferences.field.label.budget': 'Driver Budget',
					'preferences.field.summary.budget': 'Current drive- and worktime budget',
					'preferences.field.label.messages': 'Textmessages',
					'preferences.field.summary.messages': 'Newly received textmessages',
					'budget.driver': 'Driver',
					'budget.notAvailable': 'Not available',
					'budget.driverStatus': 'Driver status',
					'budget.driverStatus.0': 'Break / Rest',
					'budget.driverStatus.1': 'Available',
					'budget.driverStatus.2': 'Work',
					'budget.driverStatus.3': 'Driving',
					'budget.driverStatus.4': 'Card missing',
					'budget.startOfActivityShift': 'Start of activity / shift',
					'budget.endOfDayBreak': 'End of day break',
					'budget.remainingDrivingTime': 'Remaining driving time',
					'budget.remainingWorkingTime': 'Remaining working time',
					'budget.drivingTimeMaxUsed': 'Driving time (max/used)',
					'budget.workingTimeMaxUsed': 'Working time (max/used)',
					'budget.breakBefore': 'Break before',
					'budget.minDurationNextBreak': 'Minimum duration next break',
					'budget.remainingWeeklyDrivingTime': 'Remaining weekly driving time',
					'budget.remainingWeeklyWorkingTime': 'Remaining weekly working time',
					'budget.minDurationNextWeeklyRestPeriod': 'Minimum duration next weekly rest period',
				}
			},
			nl: {
				common: {
					'login.signIn': 'Aanmelden',
					'menu.signout': 'Uitloggen',
					'login.organisation': 'Organisatie',
					'login.username': 'Gebruikersnaam',
					'login.password': 'Wachtwoord',
					'error.signIn': 'Aanmelden mislukt',
					'login.rememberSession': 'Sessie onthouden',
					'password.currentPassword': 'Huidig wachtwoord',
					'password.newPassword': 'Nieuw wachtwoord',
					'password.confirmNewPassword': 'Nieuw wachtwoord bevestigen',
					'password.changePassword': 'Wachtwoord wijzigen',
					'error.changePassword': 'Wachtwoord wijzigen mislukt',
					'login.forgotPassword': 'Wachtwoord vergeten?',
					'reset.email': 'E-mail adres',
					'reset.resetPassword': 'Wachtwoord herstellen',
					'error.resetPassword': 'Wachtwoord herstellen mislukt',
					'Agree': 'Akkoord',
					Live: 'Live',
					'trips.title': 'Ritten',
					'activities.title': 'Activiteiten',
					'messages.title': 'Berichten',
					'messages.enterMessage': 'Bericht invoeren...',
					'messages.sendError': 'Fout bij versturen bericht',
					'map.trip.title': 'Rit',
					'Trip details': 'Rit details',
					'Location': 'Locatie',
					'Task': 'Taak',
					'checked': 'Gecontroleerd',
					'menu.forceClosePeriod': 'Periode geforceerd sluiten',
					'menu.sort': 'Sorteren',
					'menu.oldestFirst': 'Oudste eerst',
					'menu.newestFirst': 'Nieuwste eerst',
					'menu.hideShortTrips': 'Verberg korte ritten',
					'map.vehicle.title': 'Voertuig',
					'Private mode': 'Privé',
					'Private kilometers': 'Privé Kilometers',
					'Details': 'Details',
					'Retrieving trip': 'Details ophalen...',
					'trips.noTrips': 'Geen ritten',
					'trips.periodClosed': 'Periode gesloten',
					'activities.noActivities': 'Geen activiteiten',
					'trackType': 'Rittype',
					'reason': 'Reden',
					'Required': 'Verplicht',
					'BUSINESS': 'Zakelijk',
					'PRIVATE': 'Prive',
					'COMMUTER': 'Woonwerk',
					'Ignition': 'Contact',
					'IGNITION_ON': 'Aan',
					'IGNITION_OFF': 'Uit',
					'Online': 'Online',
					'Offline': 'Offline',
					'kilometrage': 'Kilometerstand',
					'detour': 'Omrijden',
					'detourDistance': 'Omgereden kilometers',
					'detourDescription': 'Omschrijving',
					'detourReference': 'Referentie',
					'detourTrackType': 'Omrijden rittype',
					'create': 'Aanmaken',
					'update': 'Bijwerken',
					'delete': 'verwijder',
					'Saved': 'Opgeslagen',
					'cancel': 'annuleren',
					'calendar.today': 'Vandaag',
					'calendar.close': 'Sluiten',
					'Bookings': 'Boekingen',
					'Booking': 'Boeking',
					'Booking details': 'Boeking details',
					'no bookings': 'Geen boekingen',
					'Remove booking?': 'Boeking verwijderen?',
					'Now': 'Nu',
					'Pick a later date or time': 'Kies een latere datum of tijd',
					'End': 'Beëindigen',
					'End booking?': 'Boeking beëindigen?',
					'map.title': 'Kaart',
					'budget.title': 'Chauffeursbudget',
					'start': 'start',
					'end': 'eind',
					'fleet.title': 'Vloot',
					'fleet.search': 'Zoek…',
					'since': 'Sinds',
					'until': 'Tot',
					'Last seen': 'Laatst gezien',
					'messages.messageCount_zero': 'Geen berichten',
					'messages.messageCount_one': '{{count}} Bericht',
					'messages.messageCount_other': '{{count}} Berichten',
					'Select Vehicles': 'Voertuigen selecteren',
					'fleet.noVehiclesSelected': 'Geen voertuigen geselecteerd',
					'fleet.noMatches': 'Geen resultaten',
					'Departure': 'Vertrek',
					'Arrival': 'Aankomst',
					'Remaining driving/working time': 'Budget rij- en werktijd',
					'PlannedAssetRelation.Asset.wrong.state': 'Boeking is tussentijds gewijzigd',
					'PlannedAssetRelation.start.in.past': 'Start is te ver in het verleden',
					'PlannedAssetRelation.not.found': 'Boeking niet gevonden',
					'PlannedAssetRelation.editing.not.allowed': 'Wijzigen mag niet (meer)',
					'PlannedAssetRelation.overlap.found': 'Er is al een boeking in deze tijdspanne',
					'PlannedAssetRelation.maintentance.window.found': 'Er is een onderhoudsvenster in deze tijdspanne',
					'preferences.title': 'Instellingen',
					'preferences.fleet.denseFields': 'Compacte veldtekst',
					'preferences.fleet.fieldsThreshold': 'Aantal velden in de niet uitgebreide status',
					'preferences.fleet.selectFields': 'Selecteer vloot velden en volgorde',
					'preferences.selectFields.title': 'Selecteer velden',
					'preferences.map.markerSize': 'Kaartmarkeringsgrootte',
					'preferences.map.labelsEnabled': 'Toon kaartlabels standaard',
					'preferences.map.selectFields': 'Selecteer kaartlabel velden en volgorde',
					'preferences.trip.hideShort': 'Standaard korte ritten verbergen',
					'preferences.trip.distanceThreshold': 'Afstandslimiet korte rit',
					'preferences.trip.sortOrder': 'Standaard sorteer volgorde',
					'preferences.field.label.vehicle': 'Voertuig',
					'preferences.field.summary.vehicle': 'Voertuig/chauffeurnaam en online/contactstatus',
					'preferences.field.label.driver': 'Chauffeur',
					'preferences.field.summary.driver': 'Chauffeur/Voertuignaam en online/contactstatus',
					'preferences.field.label.position': 'Positie',
					'preferences.field.summary.position': 'Laatst bekende adres en locatie',
					'preferences.field.label.activity': 'Activiteit',
					'preferences.field.summary.activity': 'Laatst bekende chauffeur activiteit',
					'preferences.field.label.destination': 'Bestemming',
					'preferences.field.summary.destination': 'Actuele bestemming en geschatte reistijd',
					'preferences.field.label.task': 'Planning',
					'preferences.field.summary.task': 'Actuele rit/locatie/taak',
					'preferences.field.label.online': 'Online',
					'preferences.field.summary.online': 'Laatst bekende verbindingsstatus',
					'preferences.field.label.ignition': 'Contact',
					'preferences.field.summary.ignition': 'Actuele contact status en snelheid',
					'preferences.field.label.budget': 'Chauffeur Budget',
					'preferences.field.summary.budget': 'Actueel reis- en werktijd budget',
					'preferences.field.label.messages': 'Tekstberichten',
					'preferences.field.summary.messages': 'Nieuw ontvangen tekstberichten',
					'budget.driver': 'Chauffeur',
					'budget.notAvailable': 'Niet beschikbaar',
					'budget.driverStatus': 'Chauffeur status',
					'budget.driverStatus.0': 'Pause / Rusten',
					'budget.driverStatus.1': 'Beschikbaar',
					'budget.driverStatus.2': 'Werk',
					'budget.driverStatus.3': 'Rijden',
					'budget.driverStatus.4': 'Kaart ontbreekt',
					'budget.startOfActivityShift': 'Start werkdag / shift',
					'budget.endOfDayBreak': 'Start nachtrust',
					'budget.remainingDrivingTime': 'Budget rijtijd',
					'budget.remainingWorkingTime': 'Budget werktijd',
					'budget.drivingTimeMaxUsed': 'Rijtijd (max / gebruikt)',
					'budget.workingTimeMaxUsed': 'Werktijd (max / gebruikt)',
					'budget.breakBefore': 'Rust voor',
					'budget.minDurationNextBreak': 'Minimale rust',
					'budget.remainingWeeklyDrivingTime': 'Budget wekelijkse rijtijd',
					'budget.remainingWeeklyWorkingTime': 'Budget wekelijkse werktijd',
					'budget.minDurationNextWeeklyRestPeriod': 'Wekelijkse rusttijd',
				}
			},
			de: {
				common: {
					title: 'µFlotte',
					'login.signIn': 'Anmelden',
					'menu.signout': 'Abmelden',
					'login.organisation': 'Organisation',
					'login.username': 'Benutzername',
					'login.password': 'Kennwort',
					'error.signIn': 'Login Fehler',
					'login.rememberSession': 'Sitzung speichern',
					'password.currentPassword': 'Jetziges Kennwort',
					'password.newPassword': 'Neues Kennwort',
					'password.confirmNewPassword': 'Neues Kennwort bestätigen',
					'password.changePassword': 'Kennwort ändern',
					'error.changePassword': 'Kennwortänderung gescheitert',
					'login.forgotPassword': 'Kennwort vergessen?',
					'reset.email': 'E-Mail Addresse',
					'reset.resetPassword': 'Kennwort zurücksetzen',
					'error.resetPassword': 'Kennwort zurücksetzen gescheitert',
					'Agree': 'Zustimmen',
					Live: 'Live',
					'trips.title': 'Fahrten',
					'activities.title': 'Aktivitäten',
					'messages.title': 'Nachrichten',
					'messages.enterMessage': 'Nachricht eingeben...',
					'messages.sendError': 'Fehler beim Senden Nachricht',
					'map.trip.title': 'Fahrt',
					'Trip details': 'Details',
					'Location': 'Ort',
					'Task': 'Tätigkeit',
					'checked': 'Bestätigt',
					'menu.forceClosePeriod': 'Periode erzwungen schließen',
					'menu.sort': 'Sortieren',
					'menu.oldestFirst': 'Älteste erst',
					'menu.newestFirst': 'Neueste erst',
					'menu.hideShortTrips': 'Kurzfahrten ausblenden',
					'map.vehicle.title': 'Fahrzeug',
					'Private mode': 'Privat',
					'Private kilometers': 'Privat Kilometer',
					'Details': 'Details',
					'Retrieving trip': 'Details laden ...',
					'trips.noTrips': 'Keine Fahrten',
					'trips.periodClosed': 'Periode geschlossen',
					'activities.noActivities': 'Keine Aktiväten',
					'trackType': 'Fahrt-Typ',
					'reason': 'Fahrtgrund',
					'Required': 'Erforderlich',
					'BUSINESS': 'Geschäftlich',
					'PRIVATE': 'Privat',
					'COMMUTER': 'Weg zur Arbeit',
					'Ignition': 'Zündung',
					'IGNITION_ON': 'An',
					'IGNITION_OFF': 'Aus',
					'Online': 'Online',
					'Offline': 'Offline',
					'kilometrage': 'Kilometerstand',
					'detour': 'Umweg',
					'detourDistance': 'Umwegs-KM',
					'detourDescription': 'Grund für Umweg',
					'detourReference': 'Referenz',
					'detourTrackType': 'Fahrt-Typ Umweg',
					'create': 'Erstellen',
					'update': 'Aktualisieren',
					'delete': 'Entfernen',
					'Saved': 'Gespeichert',
					'cancel': 'Abbrechen',
					'calendar.today': 'Heute',
					'calendar.close': 'Schließen',
					'Bookings': 'Buchungen',
					'Booking': 'Buchung',
					'Booking details': 'Buchung Details',
					'no bookings': 'Keine Buchungen',
					'Remove booking?': 'Buchung entfernen?',
					'Now': 'Jetzt',
					'Pick a later date or time': 'Wähle ein spätere Datum oder Zeit',
					'End': 'Beenden',
					'End booking?': 'Buchung beenden?',
					'map.title': 'Karte',
					'budget.title': 'Fahrerbudget',
					'start': 'start',
					'end': 'ende',
					'fleet.title': 'Flotte',
					'fleet.search': 'Suche…',
					'since': 'Seit',
					'until': 'Bis',
					'Last seen': 'Zuletzt gesehen',
					'messages.messageCount_zero': 'Keine Nachrichten',
					'messages.messageCount_one': '{{count}} Nachricht',
					'messages.messageCount_other': '{{count}} Nachrichten',
					'Select Vehicles': 'Fahrzeuge wählen',
					'fleet.noVehiclesSelected': 'Keine Fahrzeuge ausgewählt',
					'fleet.noMatches': 'Keine Treffer',
					'Departure': 'Abfahrt',
					'Arrival': 'Ankunft',
					'Remaining driving/working time': 'Restliche Fahr-/Arbeitszeit',
					'PlannedAssetRelation.Asset.wrong.state': 'Die Buchung ist inzwischen geändert',
					'PlannedAssetRelation.start.in.past': 'Start in der Vergangenheit nicht erlaubt',
					'PlannedAssetRelation.not.found': 'Buchung nicht gefunden',
					'PlannedAssetRelation.editing.not.allowed': 'Änderungen nicht (mehr) erlaubt',
					'PlannedAssetRelation.overlap.found': 'Im angegebenen Bereich ist bereits eine Buchung vorhanden',
					'PlannedAssetRelation.maintentance.window.found': 'Im angegebenen Bereich befindet sich ein Wartungsfenster',
					'preferences.title': 'Einstellungen',
					'preferences.fleet.denseFields': 'Kompakter Feldtext',
					'preferences.fleet.fieldsThreshold': 'Felderanzahl im unerweiterten Status',
					'preferences.fleet.selectFields': 'Flotte Felder und Reihenfolge auswählen',
					'preferences.selectFields.title': 'Felder auswählen',
					'preferences.map.markerSize': 'Kartenmarkierungsgröße',
					'preferences.map.labelsEnabled': 'Kartenbeschriftungen standardmäßig anzeigen',
					'preferences.map.selectFields': 'Kartenbeschriftungen Felder und Reihenfolge auswählen',
					'preferences.trip.hideShort': 'Kurzfahrten standardmäßig ausblenden',
					'preferences.trip.distanceThreshold': 'Kurzfahrt-Schwelle',
					'preferences.trip.sortOrder': 'Standard-Sortierreihenfolge',
					'preferences.field.label.vehicle': 'Fahrzeug',
					'preferences.field.summary.vehicle': 'Fahrzeug/Fahrername und Online/Zündstatus',
					'preferences.field.label.driver': 'Fahrer',
					'preferences.field.summary.driver': 'Fahrer/Fahrzeugname und Online/Zündstatus',
					'preferences.field.label.position': 'Position',
					'preferences.field.summary.position': 'Letzte bekannte Adresse und Ort',
					'preferences.field.label.activity': 'Aktivität',
					'preferences.field.summary.activity': 'Letzte bekannte Fahreraktivität',
					'preferences.field.label.destination': 'Ziel',
					'preferences.field.summary.destination': 'Aktuelles Ziel und geschätzte Fahrzeit',
					'preferences.field.label.task': 'Planung',
					'preferences.field.summary.task': 'Aktuelle Reise/Ort/Aufgabe',
					'preferences.field.label.online': 'Online',
					'preferences.field.summary.online': 'Letzter bekannter Verbindungsstatus',
					'preferences.field.label.ignition': 'Zündung',
					'preferences.field.summary.ignition': 'Aktueller Zündstatus und Geschwindigkeit',
					'preferences.field.label.budget': 'Fahrerbudget',
					'preferences.field.summary.budget': 'Aktuelles Fahr- und Arbeitszeitbudget',
					'preferences.field.label.messages': 'Textnachrichten',
					'preferences.field.summary.messages': 'Neu empfangene Textnachrichten',
					'budget.driver': 'Fahrer',
					'budget.notAvailable': 'Nicht verfügbar',
					'budget.driverStatus': 'Fahrer-Status',
					'budget.driverStatus.0': 'Pause / Ruhe',
					'budget.driverStatus.1': 'Verfügbar',
					'budget.driverStatus.2': 'Arbeit',
					'budget.driverStatus.3': 'Fahren',
					'budget.driverStatus.4': 'Karte fehlt',
					'budget.startOfActivityShift': 'Anfang der Aktivität / Schicht',
					'budget.endOfDayBreak': 'Ende des Tages Pause',
					'budget.remainingDrivingTime': 'Restliche Fahrzeit',
					'budget.remainingWorkingTime': 'Restliche Arbeitszeit',
					'budget.drivingTimeMaxUsed': 'Fahrzeit (gesamt / genutzt)',
					'budget.workingTimeMaxUsed': 'Arbeitszeit (gesamt / genutzt)',
					'budget.breakBefore': 'Pause vor',
					'budget.minDurationNextBreak': 'Mindestdauer nächste Pause',
					'budget.remainingWeeklyDrivingTime': 'Restliche wöchentliche Fahrzeit',
					'budget.remainingWeeklyWorkingTime': 'Restliche wöchentliche Arbeitszeit',
					'budget.minDurationNextWeeklyRestPeriod': 'Mindestdauer nächste wöchentliche Ruhezeit',
				}
			}
		},
		fallbackLng: 'en',
		debug: false,

		// have a common namespace used around the full app
		ns: ['common,linqo,arealcontrol'],
		defaultNS: 'common',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false
		}
	})

export default i18n
