import { Commute, Map, MoreVert, Place, PlaceOutlined } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'
import { dateToString, durationToString } from '../services/DateUtils'
import { addressToString } from '../services/StringUtils'
import Timeline from './Timeline'
import TripMapDialog from './TripMapDialog'

export default function Track({ nested, track, reference }) {
    const { t } = useTranslation()
    const { value: mapOpen, toggle: toggleMapOpen } = useBoolean(false)

    return (
        <Stack sx={{ px: 2, py: 1 }}>
            <Stack direction='row' alignItems='center' sx={{ gap: 2 }}>
                <Commute color='primary' />
                <Typography sx={{ width: 1 }}>{t(track.trackType)}</Typography>
                {!nested &&
                    <IconButton
                        onMouseDown={event => event.stopPropagation()}
                        onClick={toggleMapOpen}
                        size='large'>
                        <Map />
                    </IconButton>
                }
                <TripMapDialog trip={track} open={mapOpen} toggle={toggleMapOpen} />
            </Stack>
            <Timeline
                time={dateToString(track.start, reference)}
                icon={<PlaceOutlined />}
                text={addressToString(track.startAddress)} />
            <Timeline
                time={durationToString(track.start, track.end)}
                icon={<MoreVert />}
                text={`${track.distance} km`}
                textVariant='body2'
                textColor='textSecondary' />
            <Timeline
                time={dateToString(track.end, reference)}
                icon={<Place />}
                text={addressToString(track.endAddress)} />
        </Stack>
    )
}
