import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Checkbox, IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BaseDialog from './BaseDialog'

export default function OrderDialog({ state, min, open, toggle }) {
    const { t } = useTranslation()
    const [fields, setFields] = state

    const moveUp = (name) => {
        let index = fields.findIndex(f => f.name === name)
        setFields(fields.map((r, i) => i === index ? fields[index - 1] : i == index - 1 ? fields[index] : r))
    }

    const moveDown = (name) => {
        let index = fields.findIndex(f => f.name === name)
        setFields(fields.map((r, i) => i === index ? fields[index + 1] : i == index + 1 ? fields[index] : r))
    }

    const toggleField = (name) => {
        let field = fields.find(f => f.name === name)
        if (!field.selected || (field.selected && fields.filter(f => f.selected).length > min))
            setFields(fields.map(f => f.name === name ? { ...f, selected: !f.selected } : f))
    }

    return (
        <BaseDialog open={open} toggle={toggle} title='preferences.selectFields.title'>
            <List disablePadding sx={{ width: 1, overflowY: 'auto' }}>
                {fields.map((f, i) =>
                    <ListItem key={f.name} divider>
                        <ListItemText>
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Checkbox checked={f.selected} onClick={() => toggleField(f.name)} />
                                <Stack width={1}>
                                    <Typography>{t(`preferences.field.label.${f.name}`)}</Typography>
                                    <Typography variant='body2' color='textSecondary'>{t(`preferences.field.summary.${f.name}`)}</Typography>
                                </Stack>
                                <Stack direction='row' spacing={1}>
                                    <IconButton onClick={() => moveUp(f.name)} size='large' disabled={i === 0}>
                                        <KeyboardArrowUp />
                                    </IconButton>
                                    <IconButton onClick={() => moveDown(f.name)} size='large' disabled={i === fields.length - 1}>
                                        <KeyboardArrowDown />
                                    </IconButton>
                                </Stack>
                            </Stack>
                        </ListItemText>
                    </ListItem>
                )}
            </List>
        </BaseDialog >
    )
}
