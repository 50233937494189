import { CalendarMonth, DirectionsCar, MoreVert } from '@mui/icons-material'
import { PickersDay } from '@mui/x-date-pickers'
import { AppBar, Badge, Checkbox, Divider, Fade, FormControl, FormControlLabel, FormLabel, Grid, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Radio, RadioGroup, Skeleton, Toolbar } from '@mui/material'
import { styled } from '@mui/styles'
import { compareAsc, compareDesc, endOfDay, endOfMonth, format, isSameDay, isToday, parseISO, startOfDay, startOfMonth, startOfToday, startOfTomorrow } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, withRouter } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import CalendarNavigation from '../components/CalendarNavigation'
import IconWithText from '../components/IconWithText'
import Loading from '../components/Loading'
import NoResults from '../components/NoResults'
import PageLayout from '../components/PageLayout'
import Track from '../components/Track'
import { useAuth } from '../hooks/useAuth'
import { useFleet } from '../hooks/useFleet'
import { usePreferences } from '../hooks/usePreferences'
import { dateToFullString } from '../services/DateUtils'
import { vehicleToString } from '../services/StringUtils'
import TrackAdminService from '../services/TrackAdminService'
import TrackService from '../services/TrackService'

const ScrollGrid = styled(Grid)(({ theme }) => ({
	width: '100%',
	overflow: 'auto',
}))

const TinyBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		fontSize: 8,
		height: 16,
		minWidth: 16,
	},
}));

function Trips(props) {
	const controller = new AbortController()
	const { t } = useTranslation()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const auth = useAuth()
	const history = useHistory()
	const fleet = useFleet()
	const preferences = usePreferences()
	const queryClient = useQueryClient()
	const [tripHideShort] = preferences.tripHideShort
	const [tripDistanceThreshold] = preferences.tripDistanceThreshold
	const [tripSortOrder] = preferences.tripSortOrder

	const [menuAnchor, setMenuAnchor] = useState(null)
	const menuOpen = Boolean(menuAnchor)
	const [sort, setSort] = useState(tripSortOrder)
	const { value: hide, setValue: setHide, toggle: toggleHide } = useBoolean(tripHideShort)

	const vehicleId = Number(props.match.params.id)
	const vehicle = fleet.vehicles.find(v => v.id === vehicleId)
	const [selectedDate, setSelectedDate] = useState(props.match.params.date ? new Date(props.match.params.date) : startOfToday())
	const [summaryDate, setSummaryDate] = useState(startOfMonth(selectedDate))
	const { value: showCalendar, setTrue: openCalendar, setFalse: closeCalendar } = useBoolean(false)

	useEffect(() => {
		if (!props.match.params.date) {
			queryClient.fetchQuery(['previousDate', vehicleId, startOfTomorrow()], async () => {
				const response = await TrackService.getClosestForVehicle(vehicleId, startOfTomorrow(), 'BEFORE', { signal: controller.signal })
				const date = response ? parseISO(response.end) : null
				selectDate(date ? date : startOfToday())
				return date
			})
		}
	}, [])

	const { data: periodClosed, isLoading: periodIsLoading } = useQuery(['periodClosed', vehicleId, startOfMonth(selectedDate)], async () => {
		const response = await TrackAdminService.isVehiclePeriodClosed(vehicleId, selectedDate.getFullYear(), selectedDate.getMonth() + 1, { signal: controller.signal })
		return response
	}, {
		enabled: !!vehicleId && !!selectedDate,
	})

	const { data: tracks, isLoading: tracksIsLoading } = useQuery(['trips', vehicleId, selectedDate], async () => {
		const response = await TrackService.getForVehicle(vehicleId, startOfDay(selectedDate), endOfDay(selectedDate), { signal: controller.signal })
		return response?.reverse()
	}, {
		enabled: !!vehicleId && !!props.match.params.date,
		refetchInterval: isToday(selectedDate) ? 60 * 1000 : null,
	})

	const summaryQuery = useQuery(['summary', vehicleId, summaryDate], async () => {
		const response = await TrackService.getForVehicle(vehicleId, summaryDate, endOfMonth(summaryDate), { signal: controller.signal })
		return response
	}, {
		enabled: !!vehicleId && !!props.match.params.date,
	})
	const { data: summary } = summaryQuery

	const previousDateQuery = useQuery(['previousDate', vehicleId, selectedDate], async () => {
		const response = await TrackService.getClosestForVehicle(vehicleId, startOfDay(selectedDate), 'BEFORE', { signal: controller.signal })
		return response ? parseISO(response.end) : null
	}, {
		enabled: !!vehicleId && !!props.match.params.date,
	})

	const nextDateQuery = useQuery(['nextDate', vehicleId, selectedDate], async () => {
		const response = await TrackService.getClosestForVehicle(vehicleId, endOfDay(selectedDate), 'AFTER', { signal: controller.signal })
		return response ? parseISO(response.end) : null
	}, {
		enabled: !!vehicleId && !!props.match.params.date,
	})

	const selectDate = useCallback((date) => {
		setSelectedDate(date)
		history.replace('/trips/' + vehicleId + '/' + format(date, 'yyyy-MM-dd'))
	}, [history, selectedDate])

	useEffect(() => {
		return () => controller.abort()
	}, [])

	const closePeriod = useMutation(variables => TrackAdminService.markVehiclePeriodClosed(vehicleId, variables.date.getFullYear(), variables.date.getMonth() + 1, variables.force, { signal: controller.signal }), {
		onSuccess: (result, variables) => {
			queryClient.setQueryData(['periodClosed', vehicleId, startOfMonth(variables.date)], true)
			queryClient.invalidateQueries(['summary', vehicleId, summaryDate])
			enqueueSnackbar(t('trips.periodClosed'), { variant: 'success' })
		},
		onError: error => {
			const key = enqueueSnackbar(error.message, {
				SnackbarProps: {
					onClick: () => closeSnackbar(key),
				},
				variant: 'warning',
				style: { whiteSpace: 'pre-line' },
				persist: true,
			})
			closePeriod.reset()
		},
	})

	const openMenu = event => {
		setMenuAnchor(event.currentTarget)
	}

	const closeMenu = event => {
		setMenuAnchor(null)
	}

	const toggleSort = (event) => {
		setSort(event.target.value)
	}

	let byStart = (a, b) => {
		let aStart = parseISO(a.start)
		let bStart = parseISO(b.start)
		return sort === 'asc' ? compareAsc(aStart, bStart) : compareDesc(aStart, bStart)
	}

	useEffect(() => {
		setHide(tripHideShort)
	}, [tripHideShort])

	useEffect(() => {
		setSort(tripSortOrder)
	}, [tripSortOrder])

	const shouldDisableDate = (date) => isToday(date) ? false : summary?.filter(t => isSameDay(date, parseISO(t.start)) || isSameDay(date, parseISO(t.end))).length === 0

	const renderDay = ({ selectedDay, ...other }) => {
		let unchecked = summary?.filter(t => !t.checked && isSameDay(other.day, parseISO(t.end))).length
		if (!other.outsideCurrentMonth && unchecked)
			return (
				<TinyBadge overlap='circular' badgeContent={unchecked} color='secondary' key={other.day.toString()}>
					<PickersDay {...other} />
				</TinyBadge>
			)
		else
			return <PickersDay {...other} />;
	}

	return (
		<PageLayout title='trips.title' extra={
			<IconButton color='inherit' onClick={openMenu} size='large'>
				<MoreVert />
			</IconButton>
		}>
			<Menu anchorEl={menuAnchor} keepMounted open={menuOpen} onClose={closeMenu} onClick={closeMenu} TransitionComponent={Fade}>
				{auth.hasPrivilege('CLOSE_ADMINISTRATION_PERIODS_FORCED') &&
					<MenuItem disabled={periodIsLoading || periodClosed} onClick={() => closePeriod.mutate({ date: selectedDate, force: true })}>{t('menu.forceClosePeriod')}</MenuItem>
				}
				<Divider />
				<MenuItem>
					<FormControl component='fieldset'>
						<FormLabel component='legend'>{t('menu.sort')}</FormLabel>
						<RadioGroup value={sort} onChange={toggleSort}>
							<FormControlLabel value='asc' control={<Radio />} label={t('menu.oldestFirst')} />
							<FormControlLabel value='desc' control={<Radio />} label={t('menu.newestFirst')} />
						</RadioGroup>
					</FormControl>
				</MenuItem>
				<Divider />
				<MenuItem>
					<FormControlLabel control={<Checkbox checked={hide} onChange={toggleHide} />} label={`${t('menu.hideShortTrips')} (<${tripDistanceThreshold}m)`} />
				</MenuItem>
			</Menu>
			<AppBar position='sticky' color='inherit'>
				<Toolbar sx={{ pl: 1 }}>
					<Grid container alignItems='center'>
						<Grid item xs>
							<IconWithText
								icon={<DirectionsCar />}
								primary={!vehicle ? <Skeleton variant='text' width={128} /> : vehicleToString(vehicle)}
								secondary={dateToFullString(selectedDate)} />
						</Grid>
						<Grid item>
							<CalendarNavigation
								previousDateQuery={previousDateQuery}
								summaryQuery={summaryQuery}
								nextDateQuery={nextDateQuery}
								selectedDate={selectedDate}
								setSelectedDate={selectDate}
								setSummaryDate={setSummaryDate}
								shouldDisableDate={shouldDisableDate}
								renderDay={renderDay}
								renderInput={({ inputRef, inputProps }) =>
									<IconButton ref={inputRef} {...inputProps} size='large' onClick={openCalendar}>
										<CalendarMonth />
									</IconButton>
								}
								open={showCalendar}
								close={closeCalendar} />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{tracksIsLoading ?
				<Loading /> :
				tracks?.length ?
					<ScrollGrid>
						<List disablePadding>
							{tracks.filter(t => !hide || t.distance >= tripDistanceThreshold / 1000).sort(byStart).map(t =>
								<ListItem key={t.id} divider disablePadding disableGutters>
									<ListItemText>
										<Track track={t} reference={selectedDate} />
									</ListItemText>
								</ListItem>
							)}
						</List>
					</ScrollGrid> :
					<NoResults text='trips.noTrips' />
			}
		</PageLayout>
	)
}

export default withRouter(Trips)