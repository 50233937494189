import { Box, Button, CircularProgress, FormControl, Stack, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import BaseLayout from '../components/BaseLayout'
import LanguageButtonGroup from '../components/LanguageButtonGroup'
import Logo from '../components/Logo'
import SubmitButton from '../components/SubmitButton'
import { useAuth } from '../hooks/useAuth'

const ScrollBar = styled(SimpleBar)(({ theme }) => ({
	height: '100%',
	padding: theme.spacing(2),
}))

function Password() {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const auth = useAuth()
	const history = useHistory()
	const location = useLocation()
	const { from } = location.state || { from: { pathname: '/' } }

	const { errors, handleChange, values, handleSubmit, isSubmitting, setSubmitting } = useFormik({
		initialValues: {
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
		},
		validateOnChange: false,
		validateOnBlur: false,
		validate() {
			const errors = {}
			if (!values.currentPassword) {
				errors.currentPassword = true
			}
			if (!values.newPassword) {
				errors.newPassword = true
			}
			if (!values.confirmNewPassword || values.newPassword !== values.confirmNewPassword) {
				errors.confirmNewPassword = true
			}
			return errors
		},
		onSubmit(values) {
			auth.change(values.currentPassword, values.newPassword).then(result => {
				setSubmitting(false)
				history.replace(from)
			}).catch(() => {
				setSubmitting(false)
				enqueueSnackbar(t('error.changePassword'), { variant: 'error', style: { whiteSpace: 'pre-line' } })
			})
		}
	})

	return (
		<BaseLayout>
			<ScrollBar>
				<Stack alignItems='center'>
					<LanguageButtonGroup />
					<Logo />
					<form onSubmit={handleSubmit} noValidate>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.currentPassword}
								variant='standard'
								label={t('password.currentPassword')}
								name='currentPassword'
								type='password'
								autoComplete='current-password'
								autoFocus value={values.currentPassword}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.newPassword}
								variant='standard'
								label={t('password.newPassword')}
								name='newPassword'
								type='password'
								value={values.newPassword}
								onChange={handleChange}
							/>
						</FormControl>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.confirmNewPassword}
								variant='standard'
								label={t('password.confirmNewPassword')}
								name='confirmNewPassword'
								type='password'
								value={values.confirmNewPassword}
								onChange={handleChange}
							/>
						</FormControl>
						<SubmitButton
							disabled={isSubmitting}
							data-testid='changePassword'>
							{t('password.changePassword')}
						</SubmitButton>
					</form>
				</Stack>
			</ScrollBar>
		</BaseLayout>
	)
}

export default withRouter(Password)