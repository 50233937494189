import { Assignment, AssignmentInd, AvTimer, DirectionsCar, Email, Flag, Person, Place } from '@mui/icons-material'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Tooltip, useMap } from 'react-leaflet'
import { usePreferences } from '../hooks/usePreferences'
import { dateToString, durationToString, toDurationString } from '../services/DateUtils'
import { addressToString, driverToString } from '../services/StringUtils'

export default function StatusTooltip({ status, messages, onClick }) {
    const { t } = useTranslation()
    const preferences = usePreferences()
    const [mapMarkerSize] = preferences.mapMarkerSize
    const map = useMap()

    const humanize = value => toDurationString(value * 60000, { units: ['h', 'm'] }, 60000)

    let fields = []
    preferences.selectedMapFields?.forEach(f => {
        switch (f.name) {
            case 'vehicle':
                fields.push(
                    <Stack key={f.name} direction='row' spacing={.5}>
                        <DirectionsCar fontSize='small' color={!status.online ? 'disabled' : status.ignitionState === 'IGNITION_ON' ? 'primary' : 'secondary'} />
                        <Typography variant='caption' display='block'>
                            {`${status.vehicle.name} ${status.driver ? `(${driverToString(status.driver)})` : ''}`}
                        </Typography>
                    </Stack>)
                break
            case 'driver':
                fields.push(
                    <Stack key={f.name} direction='row' spacing={.5}>
                        {status.driver ?
                            <Person fontSize='small' color={!status.online ? 'disabled' : status.ignitionState === 'IGNITION_ON' ? 'primary' : 'secondary'} /> :
                            <DirectionsCar fontSize='small' color={!status.online ? 'disabled' : status.ignitionState === 'IGNITION_ON' ? 'primary' : 'secondary'} />
                        }
                        <Typography variant='caption' display='block'>
                            {status.driver ?
                                `${driverToString(status.driver)} (${status.vehicle.name})` :
                                status.vehicle.name}
                        </Typography>
                    </Stack>)
                break
            case 'position':
                if (status.positionStart || (status.position && (status.position.street || status.position.city || status.position.country)) || status.location)
                    fields.push(
                        <Stack key={f.name} fontSize='small' direction='row' spacing={.5}>
                            <Place fontSize='small' color='primary' />
                            <Typography variant='caption' display='block'>
                                {`${addressToString(status.position)} ${status.location ? `(${status.location})` : ''}`}
                            </Typography>
                        </Stack>)
                break
            case 'activity':
                if (status.activityStatus)
                    fields.push(
                        <Stack key={f.name} fontSize='small' direction='row' spacing={.5}>
                            <AssignmentInd fontSize='small' color='primary' />
                            <Typography variant='caption' display='block'>
                                {`${status.activityStatus.name} ${(status.ignitionState === 'IGNITION_ON' && status.speed) ? `(${status.speed} km/h)` : `(${durationToString(status.activityStatus.start)})`}`}
                            </Typography>
                        </Stack>)
                break
            case 'destination':
                if (status.etaStatus)
                    fields.push(
                        <Stack key={f.name} fontSize='small' direction='row' spacing={.5}>
                            <Flag fontSize='small' color='primary' />
                            <Typography variant='caption' display='block'>
                                {`${addressToString(status.etaStatus.address)} ${status.etaStatus.eta ? `(ETA ${dateToString(status.etaStatus.eta)})` : ''}`}
                            </Typography>
                        </Stack>)
                break
            case 'task':
                if (status.activityStatus && (status.activityStatus.task || status.activityStatus.taskLocation || status.activityStatus.taskTrip))
                    fields.push(
                        <Stack key={f.name}>
                            {status.activityStatus.task &&
                                <Stack fontSize='small' direction='row' spacing={.5}>
                                    <Assignment fontSize='small' color='primary' />
                                    <Typography variant='caption' display='block'>
                                        {status.activityStatus.task}
                                    </Typography>
                                </Stack>
                            }
                            {status.activityStatus.taskLocation &&
                                <Stack fontSize='small' direction='row' spacing={.5}>
                                    {!status.activityStatus.task && <Assignment fontSize='small' color='primary' />}
                                    <Typography variant='caption' display='block' sx={{ ml: status.activityStatus.task ? 3 : 0 }} >
                                        {status.activityStatus.taskLocation}
                                    </Typography>
                                </Stack>
                            }
                            {status.activityStatus.taskTrip &&
                                <Stack fontSize='small' direction='row' spacing={.5}>
                                    {!status.activityStatus.taskLocation && <Assignment fontSize='small' color='primary' />}
                                    <Typography variant='caption' display='block' sx={{ ml: status.activityStatus.taskLocation ? 3 : 0 }} >
                                        {status.activityStatus.taskTrip}
                                    </Typography>
                                </Stack>
                            }
                        </Stack>)
                break
            case 'budget':
                if (status.driverBudget)
                    fields.push(
                        <Stack key={f.name} fontSize='small' direction='row' spacing={.5}>
                            <AvTimer fontSize='small' color='primary' />
                            <Typography variant='caption' display='block'>
                                {!status.driverBudget.errorCode ?
                                    `${humanize(status.driverBudget.remainingDailyDrivingTime)} (${humanize(status.driverBudget.remainingWorkingHours)})` :
                                    t('budget.notAvailable')}
                            </Typography>
                        </Stack>)
                break
            case 'messages':
                if (messages?.length)
                    fields.push(
                        <Stack key={f.name} fontSize='small' direction='row' spacing={.5}>
                            <Email fontSize='small' color='primary' />
                            <Typography variant='caption' display='block'>
                                {t(`messages.messageCount_${messages.length > 1 ? 'other' : 'one'}`, { count: messages.length })}
                            </Typography>
                        </Stack>)
                break
        }
    })

    return (
        <Tooltip
            permanent
            direction='top'
            offset={[0, mapMarkerSize / -2]}
            eventHandlers={{
                click: () => onClick?.(map, status),
            }}>
            {fields}
        </Tooltip>
    )
}
