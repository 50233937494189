import React, { createContext, useContext, useEffect, useState } from "react"
import auth from '../services/AuthProvider'

const authContext = createContext()

// provider component to wrap app and make auth object available to child components
export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// hook for child components to get the auth object and re-render on change
export const useAuth = () => {
  return useContext(authContext)
}

// provider hook to create auth object and handle state
function useProvideAuth() {
  const [session, setSession] = useState(auth.details())
  // wrap rest methods
  const signIn = (organisation, username, password) => {
    return auth.signIn(organisation, username, password)
  }
  const signOut = () => {
    return auth.signOut()
  }
  const reset = (email) => {
    return auth.reset(email)
  }
  const change = (currentPassword, newPassword) => {
    return auth.change(currentPassword, newPassword)
  }
  const accept = () => {
    return auth.accept()
  }
  const hasPrivilege = (name) => {
    return auth.hasPrivilege(name)
  }
  const sessionHash = () => {
    return auth.sessionHash()
  }
  // subscribe to session on mount
  // because this sets state in the callback it causes any
  // component that uses this hook to re-render with the
  // latest session object
  useEffect(() => {
    const subscription = auth.detailsSubject().subscribe(setSession)
    // cleanup subscription on unmount
    return () => subscription.unsubscribe()
  }, [])
  // expose session object and auth methods
  return {
    session,
    sessionHash,
    signIn,
    signOut,
    reset,
    change,
    accept,
    hasPrivilege,
  }
}