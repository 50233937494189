import { FormControl, Stack, TextField } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import BaseLayout from '../components/BaseLayout'
import LanguageButtonGroup from '../components/LanguageButtonGroup'
import Logo from '../components/Logo'
import SubmitButton from '../components/SubmitButton'
import { useAuth } from '../hooks/useAuth'

const ScrollBar = styled(SimpleBar)(({ theme }) => ({
	height: '100%',
	padding: theme.spacing(2),
}))

function Reset() {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const auth = useAuth()
	const history = useHistory()
	const location = useLocation()
	const { from } = location.state || { from: { pathname: '/' } }

	const { errors, handleChange, values, handleSubmit, isSubmitting, setSubmitting } = useFormik({
		initialValues: {
			email: '',
		},
		validateOnChange: false,
		validateOnBlur: false,
		validate() {
			const errors = {}
			if (!values.email) {
				errors.email = true
			}
			return errors
		},
		onSubmit(values) {
			auth.reset(values.email).then(result => {
				setSubmitting(false)
				history.replace(from)
			}).catch(() => {
				setSubmitting(false)
				enqueueSnackbar(t('error.resetPassword'), { variant: 'error', style: { whiteSpace: 'pre-line' } })
			})
		}
	})

	return (
		<BaseLayout>
			<ScrollBar>
				<Stack alignItems='center'>
					<LanguageButtonGroup />
					<Logo />
					<form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
						<FormControl margin='normal' required fullWidth>
							<TextField
								error={errors.email}
								variant='standard'
								label={t('reset.email')}
								name='email'
								autoFocus value={values.email}
								onChange={handleChange}
							/>
						</FormControl>
						<SubmitButton
							disabled={isSubmitting}
							data-testid='reset'>
							{t('reset.resetPassword')}
						</SubmitButton>
					</form>
				</Stack>
			</ScrollBar>
		</BaseLayout>
	)
}

export default withRouter(Reset)