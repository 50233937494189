import { styled } from '@mui/styles';
import React from 'react'

const MainDiv = styled('div')(({ theme }) => ({
	width: 'auto',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
	[theme.breakpoints.up('md')]: {
		width: 950,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}));

function BaseLayout({ children }) {
	return (
		<MainDiv>
			{children}
		</MainDiv>
	)
}

export default BaseLayout