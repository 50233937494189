import { CalendarMonth, DirectionsCar } from '@mui/icons-material'
import { Timeline } from '@mui/lab'
import { AppBar, Grid, IconButton, Skeleton, Toolbar } from '@mui/material'
import { styled } from '@mui/styles'
import { endOfDay, isToday, startOfDay, startOfToday } from 'date-fns'
import format from 'date-fns/format'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory, withRouter } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import Activity from '../components/Activity'
import CalendarNavigation from '../components/CalendarNavigation'
import IconWithText from '../components/IconWithText'
import Loading from '../components/Loading'
import NoResults from '../components/NoResults'
import PageLayout from '../components/PageLayout'
import { useFleet } from '../hooks/useFleet'
import ActivitiesService from '../services/ActivitiesService'
import { dateToFullString, dateToString } from '../services/DateUtils'
import { vehicleToString } from '../services/StringUtils'

const ScrollGrid = styled(Grid)(({ theme }) => ({
	width: '100%',
	overflow: 'auto',
}))

function Activities(props) {
	const controller = new AbortController()
	const history = useHistory()
	const fleet = useFleet()

	const vehicleId = Number(props.match.params.id)
	const vehicle = fleet.vehicles.find(v => v.id === vehicleId)
	const [selectedDate, setSelectedDate] = useState(props.match.params.date ? new Date(props.match.params.date) : startOfToday())
	const { value: showCalendar, setTrue: openCalendar, setFalse: closeCalendar } = useBoolean(false)

	const { data: activities, isLoading: activitiesIsLoading } = useQuery(['activities', vehicleId, selectedDate], async () => {
		const response = await ActivitiesService.getForVehicle(vehicleId, startOfDay(selectedDate), endOfDay(selectedDate), { signal: controller.signal })
		return response?.reverse()
	}, {
		enabled: !!vehicleId,
		refetchInterval: isToday(selectedDate) ? 60 * 1000 : null,
	})

	const selectDate = useCallback((date) => {
		history.replace('/activities/' + vehicleId + '/' + format(date, 'yyyy-MM-dd'))
		setSelectedDate(date)
	}, [history, selectedDate])

	useEffect(() => {
		return () => controller.abort()
	}, [])

	return (
		<PageLayout title='activities.title'>
			<AppBar position='sticky' color='inherit'>
				<Toolbar sx={{ pl: 1 }}>
					<Grid container alignItems='center'>
						<Grid item xs>
							<IconWithText
								icon={<DirectionsCar />}
								primary={!vehicle ? <Skeleton variant='text' width={128} /> : vehicleToString(vehicle)}
								secondary={dateToFullString(selectedDate)} />
						</Grid>
						<Grid item>
							<CalendarNavigation
								selectedDate={selectedDate}
								setSelectedDate={selectDate}
								open={showCalendar}
								close={closeCalendar}
								renderInput={({ inputRef, inputProps }) =>
									<IconButton ref={inputRef} {...inputProps} size='large' onClick={openCalendar}>
										<CalendarMonth />
									</IconButton>
								} />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{activitiesIsLoading ?
				<Loading /> :
				activities?.length ?
					<ScrollGrid>
						<Timeline align='left' sx={{ p: 0, w: 1 }}>
							{activities.map((a, i) =>
								<Activity
									key={a.id}
									time={dateToString(a.start, selectedDate)}
									activity={a}
									lastItem={i === activities.length - 1} />)
							}
						</Timeline>
					</ScrollGrid> :
					<NoResults text='activities.noActivities' />
			}
		</PageLayout>
	)
}

export default withRouter(Activities)