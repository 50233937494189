import { Info } from '@mui/icons-material'
import { IconButton, ListItem, ListItemIcon, ListItemText, useTheme } from "@mui/material"
import React from 'react'

function IconWithText({ icon, primary, secondary, onClick }) {
    const theme = useTheme()
    return (
        <ListItem
            sx={{ px: 1, py: 0 }}
            onClick={onClick}
            secondaryAction={onClick &&
                <IconButton
                    sx={{ p: 1 }}
                    edge='end'
                    size='large'
                    onClick={onClick}>
                    <Info />
                </IconButton>}>
            <ListItemIcon sx={{ minWidth: theme.spacing(5), color: theme.palette.primary.main }}>{icon}</ListItemIcon>
            <ListItemText sx={{ my: 0.25 }} primary={primary} secondary={secondary} />
        </ListItem>
    )
}

export default IconWithText