import { useEffect, useState } from 'react'
import { useEventListener } from 'usehooks-ts'

import { useAuth } from './useAuth'

function useSessionLocalStorage(key, initialValue) {
    const auth = useAuth()
    const sessionKey = `${auth.sessionHash()}.${key}`
    // get from local storage then parse stored JSON or return initialValue
    const readValue = () => {
        // prevent build error 'window is undefined' but keep working
        if (typeof window === 'undefined')
            return initialValue
        try {
            const item = window.localStorage.getItem(sessionKey)
            return item ? parseJSON(item) : initialValue
        } catch (error) {
            console.warn(`error reading localStorage key '${sessionKey}':`, error)
            return initialValue
        }
    }

    // state to store our value. initialize passing function so logic is only executed once
    const [storedValue, setStoredValue] = useState(readValue)

    // return wrapped version of useState setter function that persists the new value to localStorage
    const setValue = value => {
        try {
            // allow value to be a function so we have the same API as useState
            const newValue = value instanceof Function ? value(storedValue) : value
            // save to local storage
            window.localStorage.setItem(sessionKey, JSON.stringify(newValue))
            // save state
            setStoredValue(newValue)
            // dispatch a custom event so all useLocalSessionStorage hooks are notified
            window.dispatchEvent(new CustomEvent('session-local-storage', { detail: { key: sessionKey, value: newValue } }))
        } catch (error) {
            console.warn(`error setting localStorage key '${sessionKey}':`, error)
        }
    }

    // update state on session change
    useEffect(() => {
        if (auth.session)
            setStoredValue(readValue())
    }, [auth.session])

    const handleStorageChange = e => {
        // only update state if event was for our key
        if (e.detail.key === sessionKey)
            setStoredValue(readValue())
    }

    useEventListener('session-local-storage', handleStorageChange)

    return [storedValue, setValue]
}

export default useSessionLocalStorage

// wrapper for JSON.parse() to support 'undefined' value
function parseJSON(value) {
    try {
        return value === 'undefined' ? undefined : JSON.parse(value ?? '')
    } catch (error) {
        console.log('error parsing value', { value })
        return undefined
    }
}
