import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import req from '../services/RequestService'
import { useAuth } from './useAuth'
import useSessionLocalStorage from './useSessionLocalStorage'

const fleetContext = createContext()

export function ProvideFleet({ children }) {
    const fleet = useProvideFleet()
    return <fleetContext.Provider value={fleet}>{children}</fleetContext.Provider>
}

export const useFleet = () => {
    return useContext(fleetContext)
}

function useProvideFleet() {
    const auth = useAuth()
    const [storedGroups, setStoredGroups] = useSessionLocalStorage('Fleet.groups', [])
    const [storedVehicles, setStoredVehicles] = useSessionLocalStorage('Fleet.vehicles', [])

    const { data: groups } = useQuery(['groups'], async () => {
        const response = await req.get(`vehicles/groups?limit=500`)
        return response
    }, {
        enabled: !!auth.session,
        staleTime: 60 * 60 * 1000,
    })

    const { data: vehicles } = useQuery(['vehicles'], async () => {
        const response = await req.get(`vehicles?limit=500`)
        return response
    }, {
        enabled: !!auth.session,
        staleTime: 60 * 60 * 1000,
    })

    useEffect(() => {
        if (auth.session && groups)
            setStoredGroups(groups.filter(r => r.assetIds.length).map(r => {
                let existing = storedGroups.find(g => r.id === g.id)
                r.selected = existing ? existing.selected : true
                return r
            }))
    }, [groups])

    useEffect(() => {
        if (auth.session && vehicles)
            setStoredVehicles(vehicles.map(r => {
                let existing = storedVehicles.find(v => r.id === v.id)
                r.selected = existing ? existing.selected : true
                return r
            }))
    }, [vehicles])

    const includes = (groups, vehicle) => {
        return groups.some(g => (g.assetIds && g.assetIds.includes(vehicle.id)))
    }

    const toggleGroup = group => {
        setStoredGroups(storedGroups.map(g => g.id === group.id ? { ...g, selected: !g.selected } : g))
    }

    const toggleVehicle = vehicle => {
        setStoredVehicles(storedVehicles.map(v => v.id === vehicle.id ? { ...v, selected: !v.selected } : v))
    }

    const toggleVehicles = () => {
        let filtered = storedGroups.some(g => g.selected) ? storedVehicles.filter(v => includes(storedGroups.filter(g => g.selected), v)) : storedVehicles
        let filteredIds = filtered.map(v => v.id)
        let noneSelected = filtered.every(v => !v.selected)
        setStoredVehicles(storedVehicles.map(v => filteredIds.includes(v.id) ? { ...v, selected: noneSelected } : v))
    }

    const getVehicle = (vehicleAssetId, options) => {
        let existing = storedVehicles.find(v => v.id === Number(vehicleAssetId))
        return existing ? Promise.resolve(existing) : req.get(`vehicles/${vehicleAssetId}`, options)
    }

    const getVehicleStatus = (assetIds, options) => req.get(`vehicles/current-statuses?limit=500&reverseGeocodeAll=true${assetIds?.length ? `&ids=${assetIds.join(',')}` : ''}`, options)

    const selectedVehicleIds = useMemo(() => {
        let filtered = storedGroups.some(g => g.selected) ? storedVehicles.filter(v => includes(storedGroups.filter(g => g.selected), v)) : storedVehicles
        let selected = filtered.filter(v => v.selected).map(v => v.id)
        return selected
    }, [storedVehicles, storedGroups])

    return {
        groups: storedGroups,
        vehicles: storedVehicles,
        selectedVehicleIds,
        toggleGroup,
        toggleVehicle,
        toggleVehicles,
        getVehicle,
        getVehicleStatus,
    }
}
