import { Assignment, AssignmentInd, AvTimer, DirectionsCar, Email, ExpandMore, Flag, Map, MoreVert, Person, Place, SettingsRemote, VpnKey } from '@mui/icons-material'
import {
    Badge, Collapse, Fade, IconButton,
    Menu,
    MenuItem,
    Stack
} from '@mui/material'
import { styled } from '@mui/styles'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { useAuth } from '../hooks/useAuth'
import { useFleet } from '../hooks/useFleet'
import { usePreferences } from '../hooks/usePreferences'
import { dateToString, durationToString, toDurationString } from '../services/DateUtils'
import { addressToString, driverToString, vehicleToString } from '../services/StringUtils'
import BudgetDialog from './BudgetDialog'
import IconWithText from './IconWithText'
import Message from './Message'
import VehicleMapDialog from './VehicleMapDialog'

const RotateButton = styled(IconButton)(({ angle, theme }) => ({
    transform: `rotate(${angle}deg)`,
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

export default function Status({ status, messages, nested }) {
    const auth = useAuth()
    const preferences = usePreferences()
    const fleet = useFleet()
    const { t } = useTranslation()
    const [menuAnchor, setMenuAnchor] = useState(null)
    const menuOpen = Boolean(menuAnchor)
    const { value: mapOpen, toggle: toggleMapOpen } = useBoolean(false)
    const { value: overflowOpen, toggle: toggleOverflowOpen } = useBoolean(false)
    const { value: budgetOpen, toggle: toggleBudgetOpen } = useBoolean(false)
    const vehicle = fleet.vehicles.find(v => v.id === status.vehicle.id)
    const hasActivityRegistration = vehicle.activityRegistrationEnabled && auth.hasPrivilege('VIEW_ACTIVITIES')
    const hasMessaging = vehicle.messagingEnabled && auth.hasPrivilege('VIEW_MESSAGES')
    const hasMessages = messages?.length > 0
    const selectedFields = preferences.selectedFleetFields
    const [fieldsThreshold] = preferences.fleetFieldsThreshold

    const openMenu = event => {
        event.stopPropagation()
        setMenuAnchor(event.currentTarget)
    }

    const closeMenu = event => {
        event.stopPropagation()
        setMenuAnchor(null)
    }

    const toggleMap = event => {
        event.stopPropagation()
        toggleMapOpen()
    }

    const toggleBudget = event => {
        event.stopPropagation()
        toggleBudgetOpen()
    }

    const humanize = value => toDurationString(value * 60000, { units: ['h', 'm'] }, 60000)

    const content = useMemo(() => {
        let result = []
        selectedFields.forEach(f => {
            switch (f.name) {
                case 'vehicle':
                    result.push(
                        <IconWithText
                            key={f.name}
                            icon={<DirectionsCar color={!status.online ? 'disabled' : status.ignitionState === 'IGNITION_ON' ? 'primary' : 'secondary'} />}
                            primary={vehicleToString(status.vehicle)}
                            secondary={driverToString(status.driver)} />)
                    break
                case 'driver':
                    result.push(
                        <IconWithText
                            key={f.name}
                            icon={status.driver ?
                                <Person color={!status.online ? 'disabled' : status.ignitionState === 'IGNITION_ON' ? 'primary' : 'secondary'} /> :
                                <DirectionsCar color={!status.online ? 'disabled' : status.ignitionState === 'IGNITION_ON' ? 'primary' : 'secondary'} />
                            }
                            primary={status.driver ? driverToString(status.driver) : vehicleToString(status.vehicle)}
                            secondary={status.driver && vehicleToString(status.vehicle)} />)
                    break
                case 'position':
                    if (status.positionStart || (status.position && (status.position.street || status.position.city || status.position.country)) || status.location)
                        result.push(
                            <IconWithText
                                key={f.name}
                                icon={<Place />}
                                primary={`${addressToString(status.position)} ${status.location ? `(${status.location})` : ''}`}
                                secondary={status.positionStart ? `${t('since')} ${dateToString(status.positionStart)} (${durationToString(status.positionStart)})` : null} />)
                    break
                case 'activity':
                    if (status.activityStatus)
                        result.push(
                            <IconWithText
                                key={f.name}
                                icon={<AssignmentInd />}
                                primary={`${status.activityStatus.name} ${(status.ignitionState === 'IGNITION_ON' && status.speed) ? `(${status.speed} km/h)` : ''}`}
                                secondary={`${t('since')} ${dateToString(status.activityStatus.start)} (${durationToString(status.activityStatus.start)})`} />)
                    break
                case 'destination':
                    if (status.etaStatus)
                        result.push(
                            <IconWithText
                                key={f.name}
                                icon={<Flag />}
                                primary={addressToString(status.etaStatus.address)}
                                secondary={status.etaStatus.eta && `ETA ${dateToString(status.etaStatus.eta)}`} />)
                    break
                case 'task':
                    if (status.activityStatus && (status.activityStatus.task || status.activityStatus.taskLocation || status.activityStatus.taskTrip))
                        result.push(
                            <Stack key={f.name} sx={{ width: 1 }}>
                                {status.activityStatus.task &&
                                    <IconWithText
                                        icon={<Assignment />}
                                        primary={t('Task')}
                                        secondary={status.activityStatus.task} />
                                }
                                {status.activityStatus.taskLocation &&
                                    <IconWithText
                                        icon={status.activityStatus.task ? null : <Assignment />}
                                        primary={t('Location')}
                                        secondary={status.activityStatus.taskLocation} />
                                }
                                {status.activityStatus.taskTrip &&
                                    <IconWithText
                                        icon={status.activityStatus.taskLocation ? null : <Assignment />}
                                        primary={t('Trip')}
                                        secondary={status.activityStatus.taskTrip} />
                                }
                            </Stack>)
                    break
                case 'online':
                    if (status.hasOwnProperty('online'))
                        result.push(
                            <IconWithText
                                key={f.name}
                                icon={<SettingsRemote />}
                                primary={t(status.online ? 'Online' : 'Offline')}
                                secondary={status.positionLastUpdate ? `${t('Last seen')} ${dateToString(status.positionLastUpdate)} (${durationToString(status.positionLastUpdate)})` : null} />)
                    break
                case 'ignition':
                    if (status.ignitionState && status.ignitionState !== 'UNKNOWN')
                        result.push(
                            <IconWithText
                                key={f.name}
                                icon={<VpnKey />}
                                primary={`${t('Ignition')} ${t(status.ignitionState)
                                    }`}
                                secondary={`${(status.ignitionState === 'IGNITION_ON' && status.speed) ? `${status.speed} km/h` : ''} `} />)
                    break
                case 'budget':
                    if (status.driverBudget)
                        result.push(
                            <IconWithText
                                key={f.name}
                                icon={<AvTimer />}
                                primary={t('Remaining driving/working time')}
                                secondary={!status.driverBudget.errorCode ?
                                    `${humanize(status.driverBudget.remainingDailyDrivingTime)} (${humanize(status.driverBudget.remainingWorkingHours)})` :
                                    t('budget.notAvailable')}
                                onClick={toggleBudget} />)
                    break
                case 'messages':
                    if (hasMessages) {
                        result.push(
                            <Stack key={f.name} sx={{ width: 1, pl: 4.5, pr: 2 }}>
                                {messages.map(m => <Message key={m.messageId} message={m} />)}
                            </Stack>)
                    } break
            }
        })
        return result
    }, [selectedFields, status, messages])

    const hasOverflow = useMemo(() => content?.length > fieldsThreshold, [content, fieldsThreshold])

    return (
        <Stack onClick={hasOverflow ? toggleOverflowOpen : null}>
            <Menu anchorEl={menuAnchor} keepMounted open={menuOpen} onClose={closeMenu} TransitionComponent={Fade}>
                <MenuItem onClick={closeMenu} component={Link} to={`trips/${status.vehicle.id}`}>{t('trips.title')}</MenuItem>
                {hasActivityRegistration &&
                    <MenuItem onClick={closeMenu} component={Link} to={`activities/${status.vehicle.id}`}>{t('activities.title')}</MenuItem>
                }
                {(hasMessaging || hasMessages) &&
                    <MenuItem onClick={closeMenu} component={Link} to={`messages/${status.vehicle.id}`}>{t('messages.title')}</MenuItem>
                }
            </Menu>
            {content.slice(0, fieldsThreshold).map((c, i) =>
                <Stack key={i} direction='row' paddingX={1} spacing={1}>
                    {c}
                    {i === 0 &&
                        <Stack direction='row' spacing={1} alignItems='flex-start'>
                            {(!nested && (status.position && status.position.latitude && status.position.longitude)) &&
                                <IconButton onClick={toggleMap} size='large'>
                                    <Map />
                                </IconButton>
                            }
                            {(hasMessaging || hasMessages) &&
                                <IconButton component={Link} to={`messages/${status.vehicle.id}`} size='large'>
                                    <Badge color='secondary' badgeContent={messages?.length}>
                                        <Email color={hasMessages ? 'primary' : 'inherit'} />
                                    </Badge>
                                </IconButton>
                            }
                            <IconButton onClick={openMenu} size='large'>
                                <MoreVert />
                            </IconButton>
                        </Stack>}
                    {(hasOverflow && i === fieldsThreshold - 1) &&
                        <Stack direction='row' alignItems='flex-end'>
                            <RotateButton angle={overflowOpen ? 180 : 0} size='large'>
                                <ExpandMore />
                            </RotateButton>
                        </Stack>
                    }
                </Stack>
            )}
            {hasOverflow &&
                <Collapse in={overflowOpen} timeout='auto' unmountOnExit>
                    <Stack paddingX={1}>
                        {content.slice(fieldsThreshold)}
                    </Stack>
                </Collapse>
            }
            {(status.position && status.position.latitude && status.position.longitude) &&
                <VehicleMapDialog
                    status={status}
                    messages={messages}
                    open={mapOpen}
                    toggle={toggleMap} />
            }
            {status.driverBudget &&
                <BudgetDialog
                    status={status}
                    open={budgetOpen}
                    toggle={toggleBudget} />
            }
        </Stack>
    )
}
