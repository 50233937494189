import { Close } from '@mui/icons-material'
import { Dialog, IconButton, Slide } from '@mui/material'
import React, { forwardRef, Fragment } from 'react'
import DialogLayout from './DialogLayout'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

export default function BaseDialog({ open, toggle, title, children, extra }) {
    return (
        <Dialog fullScreen open={open} onClose={toggle} TransitionComponent={Transition}>
            <DialogLayout title={title} extra={
                <Fragment>
                    {extra}
                    <IconButton color="inherit" onClick={toggle} size="large"><Close /></IconButton>
                </Fragment>}>
                {children}
            </DialogLayout>
        </Dialog>
    )
}