import { Lock, LockOpen } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { Fragment } from 'react'
import { useBoolean } from 'usehooks-ts'
import Map from '../components/Map'
import BaseDialog from './BaseDialog'

export default function MapDialog({ open, toggle, title, extra, bounds, details, eventHandlers, children }) {
    const { value: zoomToFit, setFalse: disableZoomToFit, toggle: toggleZoomToFit } = useBoolean(true)

    return (
        <BaseDialog
            open={open}
            toggle={toggle}
            title={title ?? 'map.title'}
            extra={
                <Fragment>
                    {extra}
                    <IconButton color='inherit' onClick={toggleZoomToFit} size='large'>
                        {zoomToFit ?
                            <Lock /> :
                            <LockOpen />}
                    </IconButton>
                </Fragment>
            }>
            <Map zoomToFit={zoomToFit}
                eventHandlers={{
                    click: eventHandlers?.click,
                    mousedown: disableZoomToFit,
                    touchstart: disableZoomToFit,
                }}
                bounds={bounds}>
                {children}
            </Map>
            {details}
        </BaseDialog>
    )
}