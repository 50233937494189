const arealControlTheme = {
	palette: {
		primary: {
			main: '#009AA3',
		},
		secondary: {
			main: '#00696F',
		},
	},
};
export default arealControlTheme;