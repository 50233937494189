import { FormControlLabel, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, Radio, RadioGroup, Slider, Switch, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoolean } from 'usehooks-ts'
import { usePreferences } from '../hooks/usePreferences'
import BaseDialog from './BaseDialog'
import OrderDialog from './OrderDialog'

export default function PreferencesDialog({ open, toggle }) {
    const { t } = useTranslation()
    const preferences = usePreferences()
    const { value: fleetOrderOpen, toggle: toggleFleetOrder } = useBoolean(false)
    const { value: mapOrderOpen, toggle: toggleMapOrder } = useBoolean(false)

    const [fleetDenseFields, setFleetDenseFields] = preferences.fleetDenseFields
    const [fleetFieldsThreshold, setFleetFieldsThreshold] = preferences.fleetFieldsThreshold
    const [fleetFieldsThresholdValue, setFleetFieldsThresholdValue] = useState(fleetFieldsThreshold)
    const [mapLabelsEnabled, setMapLabelsEnabled] = preferences.mapLabelsEnabled
    const [mapMarkerSize, setMapMarkerSize] = preferences.mapMarkerSize
    const [mapMarkerSizeValue, setMapMarkerSizeValue] = useState(mapMarkerSize)
    const [tripHideShort, setTripHideShort] = preferences.tripHideShort
    const [tripDistanceThreshold, setTripDistanceThreshold] = preferences.tripDistanceThreshold
    const [tripDistanceThresholdValue, setTripDistanceThresholdValue] = useState(tripDistanceThreshold)
    const [tripSortOrder, setTripSortOrder] = preferences.tripSortOrder

    return (
        <BaseDialog open={open} toggle={toggle} title='preferences.title'>
            <List sx={{ width: 1, pt: 0, overflowY: 'auto' }}>
                <List subheader={<ListSubheader>{t('fleet.title')}</ListSubheader>}>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.fleet.denseFields')} />
                        <ListItemSecondaryAction>
                            <Switch checked={fleetDenseFields} onChange={e => setFleetDenseFields(e.target.checked)} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.fleet.fieldsThreshold')}
                            secondary={
                                <Typography sx={{ pt: 1, pl: 1, pr: 2 }}>
                                    <Slider
                                        marks={[...Array(preferences.selectedFleetFields?.length)].map((e, i) => ({
                                            value: i + 1,
                                            label: i + 1,
                                        }))}
                                        min={2}
                                        max={preferences.selectedFleetFields?.length}
                                        value={fleetFieldsThresholdValue}
                                        onChange={(e, v) => setFleetFieldsThresholdValue(v)}
                                        onChangeCommitted={(e, v) => setFleetFieldsThreshold(v)} />
                                </Typography>
                            } />
                    </ListItem>
                    <ListItemButton onClick={toggleFleetOrder}>
                        <ListItemText
                            primary={t('preferences.fleet.selectFields')}
                            secondary={preferences.selectedFleetFields?.map(f => t(`preferences.field.label.${f.name}`)).join(', ')} />
                    </ListItemButton>
                </List>
                <OrderDialog
                    state={preferences.fleetFields}
                    min={2}
                    open={fleetOrderOpen}
                    toggle={toggleFleetOrder} />
                <List subheader={<ListSubheader>{t('map.title')}</ListSubheader>}>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.map.markerSize')}
                            secondary={
                                <Typography sx={{ pt: 1, pl: 1, pr: 2 }}>
                                    <Slider
                                        marks
                                        min={20}
                                        max={30}
                                        value={mapMarkerSizeValue}
                                        onChange={(e, v) => setMapMarkerSizeValue(v)}
                                        onChangeCommitted={(e, v) => setMapMarkerSize(v)} />
                                </Typography>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.map.labelsEnabled')} />
                        <ListItemSecondaryAction>
                            <Switch checked={mapLabelsEnabled} onChange={e => setMapLabelsEnabled(e.target.checked)} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItemButton onClick={toggleMapOrder}>
                        <ListItemText
                            primary={t('preferences.map.selectFields')}
                            secondary={preferences.selectedMapFields.map(f => t(`preferences.field.label.${f.name}`)).join(', ')} />
                    </ListItemButton>
                </List>
                <OrderDialog
                    state={preferences.mapFields}
                    min={1}
                    open={mapOrderOpen}
                    toggle={toggleMapOrder} />
                <List subheader={<ListSubheader>{t('trips.title')}</ListSubheader>}>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.trip.hideShort')} />
                        <ListItemSecondaryAction>
                            <Switch checked={tripHideShort} onChange={e => setTripHideShort(e.target.checked)} />
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.trip.distanceThreshold')}
                            secondary={
                                <Typography sx={{ pt: 1, pl: 1, pr: 2 }}>
                                    <Slider
                                        marks={[...Array(10)].map((e, i) => ({
                                            value: i * 100,
                                            label: `${i * 100}m`,
                                        }))}
                                        min={100}
                                        max={900}
                                        step={100}
                                        value={tripDistanceThresholdValue}
                                        onChange={(e, v) => setTripDistanceThresholdValue(v)}
                                        onChangeCommitted={(e, v) => setTripDistanceThreshold(v)} />
                                </Typography>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={t('preferences.trip.sortOrder')}
                            secondary={
                                <Typography component='span'>
                                    <RadioGroup value={tripSortOrder} onChange={(e) => {
                                        setTripSortOrder(e.target.value)
                                    }}>
                                        <FormControlLabel value='asc' control={<Radio />} label={t('menu.oldestFirst')} />
                                        <FormControlLabel value='desc' control={<Radio />} label={t('menu.newestFirst')} />
                                    </RadioGroup>
                                </Typography>
                            } />
                    </ListItem>
                </List>
            </List>
        </BaseDialog >
    )
}
