import { useTheme } from '@emotion/react'
import { CalendarMonth, DirectionsCar, KeyboardArrowDown, Send } from '@mui/icons-material'
import {
	AppBar,
	Fab,
	Fade,
	FormControl,
	Grid,
	IconButton,
	Input, Skeleton,
	Toolbar
} from '@mui/material'
import { styled } from '@mui/styles'
import { endOfDay, isToday, startOfDay, startOfToday } from 'date-fns'
import format from 'date-fns/format'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, withRouter } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import CalendarNavigation from '../components/CalendarNavigation'
import IconWithText from '../components/IconWithText'
import Loading from '../components/Loading'
import Message from '../components/Message'
import NoResults from '../components/NoResults'
import PageLayout from '../components/PageLayout'
import { useAuth } from '../hooks/useAuth'
import { useFleet } from '../hooks/useFleet'
import { dateToFullString, dateToISOString } from '../services/DateUtils'
import MessageService from '../services/MessageService'
import { vehicleToString } from '../services/StringUtils'

const ScrollGrid = styled(Grid)(({ theme }) => ({
	width: '100%',
	height: '100%',
	overflow: 'auto',
}))

const InputGrid = styled(Grid)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 2),
}))

function Messages(props) {
	const auth = useAuth()
	const fleet = useFleet()
	const history = useHistory()
	const theme = useTheme()
	const { enqueueSnackbar } = useSnackbar()
	const { t, i18n } = useTranslation()

	const queryClient = useQueryClient()
	const controller = new AbortController()

	const endRef = useRef()
	const { value: endRefVisible, setValue: setEndRefVisible } = useBoolean(true)

	const today = startOfToday()
	const vehicleId = Number(props.match.params.id)
	const vehicle = fleet.vehicles.find(v => v.id === vehicleId)
	const [selectedDate, setSelectedDate] = useState(props.match.params.date ? startOfDay(new Date(props.match.params.date)) : today)
	const { value: showCalendar, setTrue: openCalendar, setFalse: closeCalendar } = useBoolean(false)

	const { data: messages, isLoading: messagesIsLoading } = useQuery(['messages', vehicleId, selectedDate], async () => {
		const response = await MessageService.getForVehicle(vehicleId, startOfDay(selectedDate), endOfDay(selectedDate), { signal: controller.signal })
		return response?.reverse()
	}, {
		enabled: !!vehicleId,
		refetchInterval: isToday(selectedDate) ? 60 * 1000 : null,
	})

	const sendMessage = useMutation(message => MessageService.sendMessage(vehicleId, message.content, { signal: controller.signal }), {
		onMutate: async message => {
			await queryClient.cancelQueries('messages')
			const previousMessages = queryClient.getQueryData(['messages', vehicleId, today])
			queryClient.setQueryData(['messages', vehicleId, today], [...previousMessages, message])
			return { previousMessages }
		},
		onSuccess: (data, message, context) => {
			queryClient.setQueryData(['messages', vehicleId, today], [...context.previousMessages, data])
		},
		onError: (error, message, context) => {
			enqueueSnackbar(t('messages.sendError'), {
				variant: 'error',
			})
			setFieldValue('content', message.content)
			queryClient.setQueryData(['messages', vehicleId, today], context.previousMessages)
		},
		onSettled: (data, error, message, context) => {
			queryClient.invalidateQueries(['messages', vehicleId, today])
		},
	})

	const messagesRead = useMutation(messages => MessageService.messagesRead(messages.map(m => m.id)), {
		onSuccess: data => {
			queryClient.invalidateQueries('unread')
			queryClient.setQueryData(['messages', vehicleId, selectedDate], messages.map(m => data.find(d => d.id === m.id) ?? m))
		},
	})

	useEffect(() => {
		let unread = messages?.filter(m => m.receivers?.find(r => r.isMine && !r.opened))
		if (unread?.length)
			messagesRead.mutate(unread)
	}, [messages])

	useEffect(() => {
		return () => controller.abort()
	}, [])

	const { values, handleChange, handleSubmit, dirty, setFieldValue, resetForm } = useFormik({
		initialValues: {
			content: '',
		},
		onSubmit(values) {
			sendMessage.mutate({
				id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
				messageState: 'SENT',
				isMine: true,
				sent: dateToISOString(new Date()),
				content: values.content,
			})
			resetForm()
		}
	})

	const selectDate = useCallback((date) => {
		history.replace('/messages/' + vehicleId + '/' + format(date, 'yyyy-MM-dd'))
		setSelectedDate(date)
	}, [history, selectedDate])

	useEffect(() => {
		if (endRefVisible)
			scrollToBottom()
	}, [messages])

	const scrollToBottom = () => {
		if (endRef.current)
			endRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
	}

	const handleScroll = (e) => {
		setEndRefVisible(Math.trunc(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight)
	}

	return (
		<PageLayout title='messages.title'>
			<AppBar position='sticky' color='inherit'>
				<Toolbar sx={{ pl: 1 }}>
					<Grid container alignItems='center'>
						<Grid item xs>
							<IconWithText
								icon={<DirectionsCar />}
								primary={!vehicle ? <Skeleton variant='text' width={256} /> : vehicleToString(vehicle)}
								secondary={dateToFullString(selectedDate)} />
						</Grid>
						<Grid item>
							<CalendarNavigation
								selectedDate={selectedDate}
								setSelectedDate={selectDate}
								open={showCalendar}
								close={closeCalendar}
								renderInput={({ inputRef, inputProps }) =>
									<IconButton ref={inputRef} {...inputProps} size='large' onClick={openCalendar}>
										<CalendarMonth />
									</IconButton>
								} />
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<ScrollGrid container alignContent='flex-start' onScroll={handleScroll}>
				{messagesIsLoading ?
					<Loading /> :
					messages?.length ?
						messages.map(m => <Message message={m} key={m.id} />) :
						<NoResults text={t('messages.messageCount_zero', { count: 0 })} />
				}
				<div ref={endRef} />
				<Fade in={!endRefVisible} unmountOnExit>
					<Fab color='primary' onClick={scrollToBottom} style={{
						margin: 0,
						top: 'auto',
						right: theme.spacing(4),
						bottom: 128,
						left: 'auto',
						position: 'fixed',
					}}>
						<KeyboardArrowDown />
					</Fab>
				</Fade>
			</ScrollGrid>
			{auth.hasPrivilege('SEND_MESSAGES') &&
				<AppBar position='sticky' color='inherit'>
					<form onSubmit={handleSubmit} noValidate>
						<InputGrid container spacing={1}>
							<Grid item xs>
								<FormControl margin='normal' required fullWidth>
									<Input
										multiline
										maxRows={16}
										name='content'
										placeholder={t('messages.enterMessage')}
										value={values.content}
										onChange={handleChange} />
								</FormControl>
							</Grid>
							<Grid item>
								<IconButton
									type='submit'
									color='primary'
									disabled={!values.content.length || sendMessage.isLoading}
									size='large'>
									<Send />
								</IconButton>
							</Grid>
						</InputGrid>
					</form>
				</AppBar>
			}
		</PageLayout>
	)
}

export default withRouter(Messages)