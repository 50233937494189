import { AppBar, Box, Collapse } from "@mui/material";
import React from 'react';

function BottomCollapse({ open, children }) {
    return (
        <AppBar position='sticky' color='inherit' sx={{ maxHeight: '50%' }}>
            <Collapse in={open} sx={{ overflow: 'auto' }}>
                <Box sx={{ py: 1, width: 1 }}>
                    {children}
                </Box>
            </Collapse>
        </AppBar>
    )
}

export default BottomCollapse
