/*
Functions:
    getForVehicle(vehicleId, start, end)
*/
import { dateToISOString } from './DateUtils';
import req from './RequestService';

const ActivitiesService = {
    getForVehicle: (vehicleId, start, end, options) => req.get(`vehicles/${vehicleId}/activities?start=${dateToISOString(start)}&end=${dateToISOString(end)}&limit=500`, options),
}
export default ActivitiesService;
