import { createContext, useContext, useEffect, useMemo } from 'react'
import { useAuth } from './useAuth'
import useSessionLocalStorage from './useSessionLocalStorage'

const fleetOptions = ['vehicle', 'driver', 'position', 'activity', 'destination', 'task', 'online', 'ignition', 'budget', 'messages']
const fleetDefaults = ['vehicle', 'position', 'activity', 'destination', 'task', 'online', 'ignition', 'budget', 'messages']
const mapOptions = ['vehicle', 'driver', 'position', 'activity', 'destination', 'task', 'budget', 'messages']
const mapDefaults = ['vehicle', 'messages']

const preferencesContext = createContext()

export function ProvidePreferences({ children }) {
    const preferences = useProvidePreferences()
    return <preferencesContext.Provider value={preferences}>{children}</preferencesContext.Provider>
}

export const usePreferences = () => {
    return useContext(preferencesContext)
}

function useProvidePreferences() {
    const auth = useAuth()
    const fleetDenseFields = useSessionLocalStorage('Fleet.denseFields', false)
    const fleetFieldsThreshold = useSessionLocalStorage('Fleet.fieldsThreshold', 3)
    const storedFleetFields = useSessionLocalStorage('Fleet.fields', [])
    const [fleetFields, setFleetFields] = storedFleetFields
    const storedMapFields = useSessionLocalStorage('Map.fields', [])
    const [mapFields, setMapFields] = storedMapFields
    const mapLabelsEnabled = useSessionLocalStorage('Map.labelsEnabled', false)
    const mapMarkerSize = useSessionLocalStorage('Map.markerSize', 24)
    const tripHideShort = useSessionLocalStorage('Trip.hideShort', false)
    const tripDistanceThreshold = useSessionLocalStorage('Trip.distanceThreshold', 100)
    const tripSortOrder = useSessionLocalStorage('Trip.sortOrder', 'desc')

    const selectedFleetFields = useMemo(() => fleetFields.filter(f => f.selected), [fleetFields])
    const selectedMapFields = useMemo(() => mapFields.filter(f => f.selected), [mapFields])

    function initFields(fields, options, defaults) {
        let existing = fields.filter(f => options.includes(f.name))
        let missing = options.filter(o => !existing.map(e => e.name).includes(o)).map(o => ({
            name: o,
            selected: !defaults || defaults.includes(o),
        }))
        return [...existing, ...missing]
    }

    useEffect(() => {
        if (auth.session) {
            setFleetFields(initFields(fleetFields, fleetOptions, fleetDefaults))
            setMapFields(initFields(mapFields, mapOptions, mapDefaults))
        }
    }, [auth.session])

    return {
        fleetDenseFields,
        fleetFieldsThreshold,
        fleetFields: storedFleetFields,
        selectedFleetFields,
        mapFields: storedMapFields,
        selectedMapFields,
        mapLabelsEnabled,
        mapMarkerSize,
        tripHideShort,
        tripDistanceThreshold,
        tripSortOrder,
    }
}
