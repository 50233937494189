import { divIcon, latLngBounds } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { renderToString } from "react-dom/server"
import { MapConsumer, MapContainer, TileLayer } from 'react-leaflet'
import Loading from './Loading'

export function determineBounds(positions) {
	let bounds = latLngBounds(positions)
	let ne = bounds.getNorthEast()
	let sw = bounds.getSouthWest()
	bounds = bounds.extend([[ne.lat + ((ne.lat - sw.lat) * 0.1), ne.lng + ((ne.lng - sw.lng) * 0.1)], [sw.lat + ((sw.lat - ne.lat) * 0.1), sw.lng + ((sw.lng - ne.lng) * 0.1)]])
	return bounds
}

export function createIcon(content) {
	const customMarkerIcon = divIcon({
		className: 'leaflet-fa-map-marker',
		iconAnchor: [0, 0],
		html: renderToString(content),
	})
	return customMarkerIcon
}

export default function Map({ style, bounds, zoomToFit, eventHandlers, children }) {
	const [map, setMap] = useState(null)

	const resizeObserver = new ResizeObserver(() => {
		map.invalidateSize()
	})

	useEffect(() => {
		if (map) {
			map.on('click', () => eventHandlers?.click?.(map))
			map.on('mousedown', () => eventHandlers?.mousedown?.(map))
			map.on('touchstart', () => eventHandlers?.touchstart?.(map))
			resizeObserver.observe(map._container)
		}
		return () => resizeObserver.disconnect()
	}, [map])

	useEffect(() => {
		if (map && zoomToFit && bounds) {
			map.fitBounds(bounds)
		}
	}, [zoomToFit, bounds])

	return (
		bounds ?
			<MapContainer
				whenCreated={setMap}
				style={style ?? { minHeight: '100px', minWidth: '100px', height: '100%', width: '100%' }}
				bounds={bounds}
				scrollWheelZoom={true}>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>
				{zoomToFit &&
					<MapConsumer>
						{(map) => {
							if (bounds)
								map.fitBounds(bounds)
							return null
						}}
					</MapConsumer>
				}
				{children}
			</MapContainer> :
			<Loading />
	)
}


