 const linqoTheme = {
	palette: {
		primary: {
			main: '#faaf40',
		},
		secondary: {
			main: '#c38001',
		},
	},
};
export default linqoTheme;