import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { differenceInMilliseconds, format, fromUnixTime, isSameDay, isSameYear, parseISO } from 'date-fns'
import deLocale from "date-fns/locale/de"
import enLocale from "date-fns/locale/en-GB"
import nlLocale from "date-fns/locale/nl"
import humanizeDuration from "humanize-duration"
import i18n from './i18n'

export const localeMap = {
	en: enLocale,
	nl: nlLocale,
	de: deLocale,
}

i18n.on('languageChanged', (lng) => {
	LocalizedAdapter.locale = localeMap[lng]
})

export class LocalizedAdapter extends AdapterDateFns {
	locale = localeMap[i18n.language]
}

export const dateToISOString = (date) => {
	if (!date)
		return null
	var result = date instanceof Date ? date.toISOString() : date
	return result.replace(/\.\d{3}Z/, 'Z')
}

export const dateToShortString = (date, reference = new Date()) => {
	if (!date)
		return null
	if (!(date instanceof Date))
		date = parseISO(date)
	return format(date,
		isSameDay(date, reference) ?
			'HH:mm' :
			isSameYear(date, reference) ?
				'dd-MM HH:mm' :
				'dd-MM-yyyy HH:mm',
		{ locale: localeMap[i18n.language] })
		.replace('-', '\u2011')
}

export const dateToString = (date, reference = new Date()) => {
	if (!date)
		return null
	if (!(date instanceof Date))
		date = parseISO(date)
	return format(date,
		isSameDay(date, reference) ?
			'HH:mm' :
			isSameYear(date, reference) ?
				'dd MMM HH:mm' :
				'dd MMM yyyy HH:mm',
		{ locale: localeMap[i18n.language] })
}

export const dateToFullString = (date, reference = new Date()) => {
	if (!date)
		return null
	if (!(date instanceof Date))
		date = parseISO(date)
	return format(date,
		isSameYear(date, reference) ?
			'd MMMM' :
			'd MMMM yyyy',
		{ locale: localeMap[i18n.language] })
}

export const epochToString = (epoch) => {
	if (!epoch)
		return null
	let date = fromUnixTime(epoch / 1000)
	return dateToString(date)
}

export const durationToString = (start, end = new Date()) => {
	start = start instanceof Date ? start : parseISO(start)
	end = end instanceof Date ? end : parseISO(end)
	return toDurationString(Math.abs(differenceInMilliseconds(start, end)), { units: ['d', 'h', 'm'], round: true }, 60000)
}

const defaultHumanizer = humanizeDuration.humanizer({
	language: 'en',
	fallbacks: ['en'],
	spacer: '',
	delimiter: ' ',
	languages: {
		de: {
			y: () => 'y',
			mo: () => 'mo',
			w: () => 'w',
			d: () => 'd',
			h: () => 'h',
			m: () => 'm',
			s: () => 's',
			ms: () => 'ms',
		},
		en: {
			y: () => 'y',
			mo: () => 'mo',
			w: () => 'w',
			d: () => 'd',
			h: () => 'h',
			m: () => 'm',
			s: () => 's',
			ms: () => 'ms',
		},
		nl: {
			y: () => 'j',
			mo: () => 'ma',
			w: () => 'w',
			d: () => 'd',
			h: () => 'u',
			m: () => 'm',
			s: () => 's',
			ms: () => 'ms',
		},
	}
})

export const toDurationString = (millis, options, lessThan) => typeof lessThan !== 'undefined' ?
	`${millis < 0 ? '-' : ''}${Math.abs(millis) < lessThan ? '< ' : ''}${defaultHumanizer(Math.max(lessThan, Math.abs(millis)), { ...options, language: i18n.language })}` :
	`${millis < 0 ? '-' : ''}${defaultHumanizer(Math.abs(millis), { ...options, language: i18n.language })}`
