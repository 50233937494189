import { DirectionsCar, Map, Menu as MenuIcon, Settings } from '@mui/icons-material'
import { AppBar, BottomNavigation, BottomNavigationAction, Box, CircularProgress, IconButton, ListItemIcon, MenuItem } from '@mui/material'
import { styled } from '@mui/styles'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useIsFetching, useIsMutating } from 'react-query'
import { Link, useLocation, withRouter } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { useAuth } from '../hooks/useAuth'
import BaseLayout from './BaseLayout'
import Menu from './Menu'
import PolicyDialog from './PolicyDialog'
import PreferencesDialog from './PreferencesDialog'
import TitleBar from './TitleBar'

const ContentBox = styled(Box)(({ theme }) => ({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	overflow: 'hidden',
}))

const BottomAppBar = styled(AppBar)(({ theme }) => ({
	width: '100%',
	top: 'auto',
	bottom: 0,
}))

function PageLayout({ title, extra, children }) {
	const { t } = useTranslation()
	const auth = useAuth()
	const location = useLocation()
	const isFetching = useIsFetching()
	const isMutating = useIsMutating()
	const menuRef = useRef(null)
	const { value: settingsOpen, toggle: toggleSettingsOpen } = useBoolean(false)

	const openMenu = (event) => {
		menuRef.current?.setMenuAnchor(event.currentTarget)
	}

	const toValue = (path) => {
		if (path.startsWith('/trip'))
			return 'Trip'
		else if (path.startsWith('/fleet'))
			return 'Map'
		else if (path === '/')
			return 'Fleet'
		return ''
	}

	return (
		<BaseLayout>
			<TitleBar
				title={title}
				extra={extra}
				icon={
					<IconButton edge='start' color='inherit' onClick={openMenu} size='large'>
						{isFetching || isMutating ? <CircularProgress color='inherit' size={24} /> : <MenuIcon />}
					</IconButton>
				}
				menu={
					<Menu ref={menuRef}>
						<MenuItem
							onClick={toggleSettingsOpen}>
							<ListItemIcon>
								<Settings />
							</ListItemIcon>
							{t('preferences.title')}
						</MenuItem>
						<PreferencesDialog open={settingsOpen} toggle={toggleSettingsOpen} />
					</Menu>
				} />
			<ContentBox>
				{children}
			</ContentBox>
			<BottomAppBar position='sticky' color='inherit'>
				<BottomNavigation value={toValue(location.pathname)} showLabels>
					<BottomNavigationAction label={t('fleet.title')} value='Fleet' icon={<DirectionsCar />} component={Link} to='/' />
					<BottomNavigationAction label={t('map.title')} value='Map' icon={<Map />} component={Link} to='/fleet' />
				</BottomNavigation>
			</BottomAppBar>
			<PolicyDialog open={auth.session.mustAcceptPrivacyPolicy} onClose={() => auth.accept()} />
		</BaseLayout>
	)
}

export default withRouter(PageLayout)