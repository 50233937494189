import { Label, LabelOffOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { useBoolean } from 'usehooks-ts'
import { usePreferences } from '../hooks/usePreferences'
import BottomCollapse from './BottomCollapse'
import MapDialog from './MapDialog'
import Status from './Status'
import StatusMarker from './StatusMarker'
import StatusTooltip from './StatusTooltip'

export default function VehicleMapDialog({ status, messages, open, toggle }) {
    const preferences = usePreferences()
    const [mapLabelsEnabled] = preferences.mapLabelsEnabled
    const { value: showTooltip, toggle: toggleShowTooltip } = useBoolean(mapLabelsEnabled)
    const { value: showDetails, setFalse: hideDetails, toggle: toggleDetails } = useBoolean(true)

    return (
        <MapDialog
            open={open}
            toggle={toggle}
            title={'map.vehicle.title'}
            bounds={[[status.position.latitude, status.position.longitude]]}
            extra={
                <IconButton color='inherit' onClick={toggleShowTooltip} size='large'>
                    {showTooltip ?
                        <Label /> :
                        <LabelOffOutlined />}
                </IconButton>
            }
            details={
                <BottomCollapse open={showDetails}>
                    <Status nested status={status} messages={messages} />
                </BottomCollapse>
            }
            eventHandlers={{
                click: hideDetails,
            }}>
            <StatusMarker
                key={status.vehicle.code}
                status={status}
                onClick={toggleDetails}>
                {showTooltip && <StatusTooltip status={status} messages={messages} />}
            </StatusMarker>
        </MapDialog>
    )
}