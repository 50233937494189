const trasecTheme = {
	palette: {
		primary: {
			main: '#0070bc',
		},
		secondary: {
			main: '#3ca31a',
		},
	},
};
export default trasecTheme;